import React, { useState } from "react";
import { SearchPNG, LoginImg, Cart, WhiteList, LogoImg } from "../constant";
import "../assets/css/style.css";
const Header = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      {/* Header Start Here */}
      <header id="headerTop">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2 col-5">
              <div className="logo_box">
                <a href="/">
                  <figure>
                    <img src={LogoImg} alt="" className="img-fluid" />
                  </figure>
                </a>
              </div>
            </div>
            <div className="col-lg-7 col-1">
              <div className="navbar_menus">
                <ul className="menus">
                  {/* <li class="menu-items">
                            <div class="dropdown">
                                <a href="shop" class="btn btn-secondary dropdown-toggle">
                                    All Plants
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a class="dropdown-item" href="premium-product">OTHERS PLANTS</a></li>
                                </ul>
                            </div>
                        </li> */}
                  <li className="menu-items">
                    <a href="shop">All Plants</a>
                  </li>
                  <li className="menu-items">
                    <a href="nutrients">Nutrients &amp; Additives</a>
                  </li>
                  <li className="menu-items">
                    <a href="kigi-birds">Kigi Birds</a>
                  </li>
                  <li className="menu-items">
                    <a href="planting-mixes">Planting Mixes</a>
                  </li>
                  <li className="menu-items">
                    <a href="premium-product">Other Products</a>
                  </li>
                  <li className="menu-items">
                    <a href="donation">Donate</a>
                  </li>
                  <li className="menu-items">
                    <a href="contact">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="right-side">
                <ul className="actions-link">
                  <li className="search" id="searchBtn" onClick={handleOpen}>
                    <img src={SearchPNG} className="img-fluid" alt="123"  />
                  </li>
                  <li className="auth">
                    <a href="login">
                      <img src={LoginImg} className="img-fluid" alt="123" />
                    </a>
                  </li>
                  <li className="cart">
                    <a href="cart">
                      <img src={Cart} className="img-fluid" alt="123" />
                    </a>
                    <span className="count">01</span>
                  </li>
                  <li className="wishlist">
                    <a href="wishlist">
                      <img src={WhiteList} className="img-fluid" alt="123" />
                    </a>
                  </li>
                  <li className="login-btn">
                    <a href="login" className="kigi-btn">
                      Login
                    </a>
                  </li>
                  <li className="canvas_btn">
                    <i className="fa fa-bars" aria-hidden="true" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* Header End Here */}
      {/* Go To Top Button */}
      {/* <div class="go_to_top">
    <a href="#header-top"><i class="fa fa-angle-up" aria-hidden="true"></i></a>
</div> */}
      {/* Go To Top Button */}
      {/* Mobile Header Start Here */}
      <div className="mobile_header">
        <div className="cancel">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={25}
            height={25}
            fill="#000"
            className="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>
        <ul className="mobile_menus">
          <li className="menu_items">
            <a className="menu_links active_menu" href="shop">
              All Plants
            </a>
          </li>
          <li className="menu_items">
            <a className="menu_links" href="premium-product">
              Others plants
            </a>
          </li>
          <li className="menu_items">
            <a className="menu_links" href="nutrients">
              Nutrients &amp; Additives
            </a>
          </li>
          <li className="menu_items">
            <a className="menu_links" href="kigi-birds">
              Kigi Birds
            </a>
          </li>
          <li className="menu_items">
            <a className="menu_links" href="planting-mixes">
              Planting Mixes
            </a>
          </li>
          <li className="menu_items">
            <a className="menu_links" href="shop">
              Products
            </a>
          </li>
          <li className="menu_items">
            <a className="menu_links" href="campaign">
              Donate
            </a>
          </li>
          <li className="menu_items">
            <a className="menu_links" href="contact">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
      {/* Mobile Header End Here */}
      {/* Search Bar Sec Start Here */}
      
      {modalOpen ? (
        <div className={`search-bar ${modalOpen ?  "opensss" : ""}`} >
          <div className="container">
            <form action>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Search..."
                  className="form-control"
                />
                <button type="button" className="search">
                  <i className="fa fa-search" aria-hidden="true" />
                </button>
              </div>
            </form>
            <div className="cancel" onClick={closeModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={26}
                height={26}
                fill="#000"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
          </div>
        </div>
      ) : null}
      {/* Search Bar Sec End Here */}
    </>
  );
};

export default Header;
