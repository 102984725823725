import React from "react";
import { Btn,TiktokImg } from "../constant";
import "../assets/css/style.css";

const Footer = () => {
  return (
    <>
      {/* Newsletter Sec Start Here */}
      <section className="newsletter-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="heading">
                <h2>Subscribe To Our Newsletter To Stay Updated</h2>
              </div>
            </div>
            <div className="col-lg-5">
              <form>
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Enter Your Email"
                    className="form-control"
                  />
                </div>
                <div className="button-group">
                  <button className="btn">
                    <img src={Btn} className="img-fluid" alt="123" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* Newsletter Sec End Here */}
      {/* Footer Start Here */}
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-content">
                <div className="head">
                  <h4>About</h4>
                </div>
                <p>
                  Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed
                  Do Eiusmod
                </p>
                <ul className="icons">
                  <li>
                    <a href="#">
                      <i className="fa fa-instagram" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-youtube-play" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={TiktokImg} className="img-fluid" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-pinterest-p" aria-hidden="true" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-content scnd-content">
                <div className="head">
                  {/* <h4>RESOURCES</h4> */}
                  <h4>QUICK LINKS</h4>
                </div>
                <ul className="menus">
                  <li>
                    <a href="about">About Us</a>
                  </li>
                  <li>
                    <a href="gift-certificate">Gift Certificates</a>
                  </li>
                  <li>
                    <a href="gallery">Image Gallery</a>
                  </li>
                  <li>
                    <a href="blogs">Blogs</a>
                  </li>
                  <li>
                    <a href="faqs">Faqs</a>
                  </li>
                  <li>
                    <a href="shipping">Shipping Policy</a>
                  </li>
                  <li>
                    <a href="videos">Videos</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-content thrd-content">
                <div className="head">
                  <h4>COMPANY INFO</h4>
                </div>
                <ul className="menus">
                  <li />
                  <li>
                    <h6>Address : </h6>
                    <a>8182 Fulton Rd Amirican Plain, MA 02130</a>
                  </li>
                  <li />
                  <li>
                    <h6>Email : </h6>
                    <a>johndeo@kiginursery.com</a>
                  </li>
                  <li />
                  <li>
                    <h6>Number : </h6>
                    <a>123456789</a>
                  </li>
                  {/* <li><a href="faqs">Faqs</a></li> */}
                  {/* <li><a href="shipping">Shipping Policy</a></li> */}
                  {/* <li><a href="campaign">Campaign</a></li> */}
                  {/* <li><a href="videos">Videos</a></li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="big-logo">
            <h1>Kigi NURSERY</h1>
          </div>
          <div className="copyright">
            <p>Copyrights © 2022 Kigi NURSERY. All rights reserved.</p>
          </div>
        </div>
      </footer>
      {/* Footer End Here */}
    </>
  );
};

export default Footer;
