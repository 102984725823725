import React from "react";
import {
  BlogAfter,
  Nutrient1,
  Nutrient2,
  Nutrient3,
  Nutrient4,
  ProAfter,
} from "../constant";
import "../assets/css/shop.css";
import Header from "../component/Header";
import Footer from "../component/Footer";

const Nutrients = () => {
  return (
    <>
      <Header />

      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Nutrients and Additives</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Shop Sec Start Here */}
      <section className="nutrients similar">
        <div className="before">
          <figure>
            <img src={BlogAfter} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="heading">
            <h2>Browse From Our Nutrients and Additives</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.{" "}
            </p>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail3">
                  <div className="pro-img">
                    <figure>
                      <img src={Nutrient1} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Super B+ D-2-1</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail3">
                  <div className="pro-img">
                    <figure>
                      <img src={Nutrient2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Super B+ D-2-1</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail3">
                  <div className="pro-img">
                    <figure>
                      <img src={Nutrient3} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Super B+ D-2-1</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail3">
                  <div className="pro-img">
                    <figure>
                      <img src={Nutrient4} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Super B+ D-2-1</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail3">
                  <div className="pro-img">
                    <figure>
                      <img src={Nutrient1} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Super B+ D-2-1</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail3">
                  <div className="pro-img">
                    <figure>
                      <img src={Nutrient2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Super B+ D-2-1</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail3">
                  <div className="pro-img">
                    <figure>
                      <img src={Nutrient3} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Super B+ D-2-1</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail3">
                  <div className="pro-img">
                    <figure>
                      <img src={Nutrient4} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Super B+ D-2-1</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail3">
                  <div className="pro-img">
                    <figure>
                      <img src={Nutrient1} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Super B+ D-2-1</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail3">
                  <div className="pro-img">
                    <figure>
                      <img src={Nutrient2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Super B+ D-2-1</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail3">
                  <div className="pro-img">
                    <figure>
                      <img src={Nutrient3} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Super B+ D-2-1</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail3">
                  <div className="pro-img">
                    <figure>
                      <img src={Nutrient4} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Super B+ D-2-1</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="paginatio-count">
            <ul className="pagination-list">
              <li className="pagi arrow">
                <a href="#">
                  <i className="fa fa-angle-left" aria-hidden="true" />
                </a>
              </li>
              <li className="pagi">
                <a href="#">1</a>
              </li>
              <li className="pagi active">
                <a href="#">2</a>
              </li>
              <li className="pagi">
                <a href="#">...</a>
              </li>
              <li className="pagi">
                <a href="#">12</a>
              </li>
              <li className="pagi arrow">
                <a href="#">
                  <i className="fa fa-angle-right" aria-hidden="true" />
                </a>
              </li>
            </ul>
            <p className="total-pages">12 PRODUCTS 1 - 12</p>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={ProAfter} className="img-fluid" alt="123" />
          </figure>
        </div>
      </section>
      {/* Shop Sec End Here */}
      <Footer />
    </>
  );
};

export default Nutrients;
