import React from "react";
import "../assets/css/shop.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {

    Premium1,
  Premium2,
  Premium3,
  SmallAtferImg,
  SmallBeforeImg,
} from "../constant";

const PremiumProduct = () => {
  return (
    <>
      <Header />
      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>premium ProductS</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Shop Sec Start Here */}
      <section className="shop_sec">
        <div className="before">
          <figure>
            <img src={SmallAtferImg} className="img-fluid" alt="" />
          </figure>
        </div>
        <div className="container">
          <div className="heading">
            <h2>
              We have more than 1000 varieties, should only be for plants.
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="sidebar">
                <div className="heading">
                  <h3>Apply Filters</h3>
                </div>
                <div className="search">
                  <form action>
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control"
                    />
                    <button className="searchBtn" type="submit">
                      <i className="fa fa-search" aria-hidden="true" />
                    </button>
                  </form>
                </div>
                <div className="filter">
                  <div className="box">
                    <div className="title">
                      <h4>Category</h4>
                    </div>
                    <ul className="list">
                      <li>
                        <label htmlFor="species1">All</label>
                        <input
                          type="checkbox"
                          id="species1"
                          className="form-check-input"
                        />
                      </li>
                      <li>
                        <label htmlFor="species2">Lorem</label>
                        <input
                          type="checkbox"
                          id="species2"
                          className="form-check-input"
                        />
                      </li>
                      <li>
                        <label htmlFor="species3">Lorem</label>
                        <input
                          type="checkbox"
                          id="species3"
                          className="form-check-input"
                        />
                      </li>
                      <li>
                        <label htmlFor="species4">Lorem</label>
                        <input
                          type="checkbox"
                          id="species4"
                          className="form-check-input"
                        />
                      </li>
                    </ul>
                    <div className="load-more">
                      <a href="#">Load More</a>
                    </div>
                  </div>
                  <div className="price-range">
                    <div className="title">
                      <h4>Price</h4>
                    </div>
                    <div className="input-group">
                      <div className="form-group">
                        <label htmlFor="From">From</label>
                        <input type="text" id="From" className="form-control" />
                      </div>
                      <div className="seprator" />
                      <div className="form-group">
                        <label htmlFor="To">To</label>
                        <input type="text" id="To" className="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="product-box">
                    <a href="product-detail4">
                      <div className="pro-img">
                        <figure>
                          <img
                            src={Premium1}
                            alt=""
                            className="img-fluid"
                          />
                        </figure>
                      </div>
                      <div className="pro-detail">
                        <div className="name">
                          <h4>Whisper Coneflower</h4>
                        </div>
                        <div className="price">
                          <h4>$250.00</h4>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="product-box">
                    <a href="product-detail4">
                      <div className="pro-img">
                        <figure>
                          <img
                            src={Premium2}
                            alt=""
                            className="img-fluid"
                          />
                        </figure>
                      </div>
                      <div className="pro-detail">
                        <div className="name">
                          <h4>Whisper Coneflower</h4>
                        </div>
                        <div className="price">
                          <h4>$250.00</h4>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="product-box">
                    <a href="product-detail4">
                      <div className="pro-img">
                        <figure>
                          <img
                            src={Premium3}
                            alt=""
                            className="img-fluid"
                          />
                        </figure>
                      </div>
                      <div className="pro-detail">
                        <div className="name">
                          <h4>Whisper Coneflower</h4>
                        </div>
                        <div className="price">
                          <h4>$250.00</h4>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="product-box">
                    <a href="product-detail4">
                      <div className="pro-img">
                        <figure>
                          <img
                            src={Premium1}
                            alt=""
                            className="img-fluid"
                          />
                        </figure>
                      </div>
                      <div className="pro-detail">
                        <div className="name">
                          <h4>Whisper Coneflower</h4>
                        </div>
                        <div className="price">
                          <h4>$250.00</h4>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="product-box">
                    <a href="product-detail4">
                      <div className="pro-img">
                        <figure>
                          <img
                            src={Premium2}
                            alt=""
                            className="img-fluid"
                          />
                        </figure>
                      </div>
                      <div className="pro-detail">
                        <div className="name">
                          <h4>Whisper Coneflower</h4>
                        </div>
                        <div className="price">
                          <h4>$250.00</h4>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="product-box">
                    <a href="product-detail4">
                      <div className="pro-img">
                        <figure>
                          <img
                            src={Premium3}
                            alt=""
                            className="img-fluid"
                          />
                        </figure>
                      </div>
                      <div className="pro-detail">
                        <div className="name">
                          <h4>Whisper Coneflower</h4>
                        </div>
                        <div className="price">
                          <h4>$250.00</h4>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="product-box">
                    <a href="product-detail4">
                      <div className="pro-img">
                        <figure>
                          <img
                            src={Premium1}
                            alt=""
                            className="img-fluid"
                          />
                        </figure>
                      </div>
                      <div className="pro-detail">
                        <div className="name">
                          <h4>Whisper Coneflower</h4>
                        </div>
                        <div className="price">
                          <h4>$250.00</h4>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="product-box">
                    <a href="product-detail4">
                      <div className="pro-img">
                        <figure>
                          <img
                            src={Premium2}
                            alt=""
                            className="img-fluid"
                          />
                        </figure>
                      </div>
                      <div className="pro-detail">
                        <div className="name">
                          <h4>Whisper Coneflower</h4>
                        </div>
                        <div className="price">
                          <h4>$250.00</h4>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="product-box">
                    <a href="product-detail4">
                      <div className="pro-img">
                        <figure>
                          <img
                            src={Premium3}
                            alt=""
                            className="img-fluid"
                          />
                        </figure>
                      </div>
                      <div className="pro-detail">
                        <div className="name">
                          <h4>Whisper Coneflower</h4>
                        </div>
                        <div className="price">
                          <h4>$250.00</h4>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="product-box">
                    <a href="product-detail4">
                      <div className="pro-img">
                        <figure>
                          <img
                            src={Premium1}
                            alt=""
                            className="img-fluid"
                          />
                        </figure>
                      </div>
                      <div className="pro-detail">
                        <div className="name">
                          <h4>Whisper Coneflower</h4>
                        </div>
                        <div className="price">
                          <h4>$250.00</h4>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="product-box">
                    <a href="product-detail4">
                      <div className="pro-img">
                        <figure>
                          <img
                            src={Premium2}
                            alt=""
                            className="img-fluid"
                          />
                        </figure>
                      </div>
                      <div className="pro-detail">
                        <div className="name">
                          <h4>Whisper Coneflower</h4>
                        </div>
                        <div className="price">
                          <h4>$250.00</h4>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="product-box">
                    <a href="product-detail4">
                      <div className="pro-img">
                        <figure>
                          <img
                            src={Premium3}
                            alt=""
                            className="img-fluid"
                          />
                        </figure>
                      </div>
                      <div className="pro-detail">
                        <div className="name">
                          <h4>Whisper Coneflower</h4>
                        </div>
                        <div className="price">
                          <h4>$250.00</h4>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="paginatio-count">
                <ul className="pagination-list">
                  <li className="pagi arrow">
                    <a href="#">
                      <i className="fa fa-angle-left" aria-hidden="true" />
                    </a>
                  </li>
                  <li className="pagi">
                    <a href="#">1</a>
                  </li>
                  <li className="pagi active">
                    <a href="#">2</a>
                  </li>
                  <li className="pagi">
                    <a href="#">...</a>
                  </li>
                  <li className="pagi">
                    <a href="#">12</a>
                  </li>
                  <li className="pagi arrow">
                    <a href="#">
                      <i className="fa fa-angle-right" aria-hidden="true" />
                    </a>
                  </li>
                </ul>
                <p className="total-pages">12 PRODUCTS 1 - 12</p>
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={SmallBeforeImg} className="img-fluid" alt="" />
          </figure>
        </div>
      </section>
      {/* Shop Sec End Here */}
      <Footer />
    </>
  );
};

export default PremiumProduct;
