import React from "react";
import "../assets/css/contact.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
const Contact = () => {
  return (
    <>
      <Header />
      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Contact Us</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Contact Sec Start Here */}
      <section className="contact_sec">
        <div className="container">
          <div className="heading">
            <h2>Let Us Answer All Your Questions </h2>
          </div>
          <div className="row">
            <div className="col-lg-2" />
            <div className="col-lg-8">
              <form action>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>First Name</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Last Name</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Phone</label>
                      <input type="tel" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input type="email" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Message</label>
                      <textarea className="form-control" defaultValue={""} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button className="btn" type="submit">
                      SUBMIT
                    </button>
                  </div>
                </div>
              </form>
              {/* <div class="button-group">
                            <a href="#">Support Center</a>
                        </div> */}
            </div>
            <div className="col-lg-2" />
            <div className="col-md-4 mt-3">
              <div className="address">
                <div className="icon">
                  <i className="fa fa-envelope" aria-hidden="true" />
                </div>
                <div className="txt">
                  <h3>Email us</h3>
                  <p>
                    Email us for general queries, including marketing and
                    partnership opportunities.
                  </p>
                  <p className="colrd">johndeo@kiginursery.com</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="address">
                <div className="icon">
                  <i className="fa fa-phone" aria-hidden="true" />
                </div>
                <div className="txt">
                  <h3>Call Us</h3>
                  <p>
                    Call us to speak to a member of our team. We are always
                    happy to help.
                  </p>
                  <p className="colrd">123456789</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="address">
                <div className="icon">
                  <i className="fa fa-map-marker" aria-hidden="true" />
                </div>
                <div className="txt">
                  <h3>Our Head Office</h3>
                  <p>
                    8182 Fulton Rd.
                    <br />
                    Amirican Plain, MA 02130
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Sec End Here */}
      <Footer />
    </>
  );
};

export default Contact;
