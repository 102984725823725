import React from "react";
import "../assets/css/videos.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { BannerVideo, PlayBtn1, poster1, poster2 } from "../constant";

const Videos = () => {
  
  function playPause() {
    let myVideo = document.getElementById("Provideo");
    if (myVideo.paused) {
      console.log("wokring if play works");
      myVideo.play();
    } else {
      console.log("wokring else play works");
      myVideo.pause();
    }
  }
  function playPause2() {
    let myVideo = document.getElementById("Provideo2");
    if (myVideo.paused) {
      console.log("wokring if play works");
      myVideo.play();
    } else {
      console.log("wokring else play works");
      myVideo.pause();
    }
  }

  return (
    <>
      <Header />
      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Videos</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Videos Sec Start Here */}
      <section className="videos_sec">
        <div className="container">
          <div className="all-videos">
            <div className="video-wrapper">
              <div className="video-container">
                <video id="Provideo" preload="metadata" poster={poster1}>
                  <source src={BannerVideo} type="video/mp4" />
                </video>
                <div className="play-button-wrapper" onClick={playPause}>
                  <div
                    title="Play video"
                    className="play-gif"
                    id="circle-play-b"
                  >
                    <img src={PlayBtn1} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="video-wrapper">
              <div className="video-container">
                <video id="Provideo2" preload="metadata" poster={poster2}>
                  <source src={BannerVideo} type="video/mp4" />
                </video>
                <div className="play-button-wrapper" onClick={playPause2}>
                  <div
                    title="Play video"
                    className="play-gif"
                    id="circle-play-b2"
                  >
                    <img src={PlayBtn1} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Videos Sec End Here */}
      <Footer />
    </>
  );
};

export default Videos;
