import React from "react";
import "../assets/css/videos.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {} from "../constant";

const ThankYou = () => {
  return (
    <>
      <Header />

      {/* Thank You Sec Css Start Here */}
      <section className="tahnk-you">
        <div className="container">
          <div className="row">
            <div className="col-lg-3" />
            <div className="col-lg-6">
              <div className="thank-box">
                <div className="heading">
                  <h3>Thank You</h3>
                </div>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Nostrum repellendus voluptates amet, provident nam,
                  consequuntur corrupti, voluptatibus explicabo unde impedit
                  nemo modi? Est placeat ipsam provident? Autem laborum
                  laboriosam doloremque!
                </p>
                <div className="button-group">
                  <a href="/" className="kigi-btn">
                    Back to home
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3" />
          </div>
        </div>
      </section>
      {/* Thank You Sec Css End Here */}
      <Footer />
    </>
  );
};

export default ThankYou;
