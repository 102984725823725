import React from "react";
import { ArrowImg, ProAfter, ProBefore } from "../constant";

import Header from "../component/Header";

const Signup = () => {
  return (
    <>
      <Header />
      <section className="login">
        <div className="before">
          <figure>
            <img src={ProBefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <div className="auth-box">
                <div className="heading">
                  <h2>Sign Up</h2>
                  <h4>
                    Welcome back to <span className="bld">Kigi Nursery</span>
                  </h4>
                  <p>
                    Already A member ?<a href="login">Sign in</a>
                  </p>
                </div>
                <form action>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>First Name</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input type="email" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" />
                      </div>
                    </div>
                  </div>
                  {/* <div class="remember-dv">
                            <div class="form-group">
                                <input type="checkbox" id="Remember" class="form-check-input" />
                                <label for="Remember">Remember Me</label>
                            </div>
                            <div class="link">
                                <a href="#">Forgot Password ?</a>
                            </div>
                        </div> */}
                  <button className="btn" type="submit">
                    <span>SignUp</span>{" "}
                    <img src={ArrowImg} className="img-fluid" />
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-3" />
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={ProAfter} className="img-fluid" />
          </figure>
        </div>
      </section>
    </>
  );
};

export default Signup;
