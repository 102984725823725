import "./App.css";
// import "./assets/css/style.css";

import "bootstrap/dist/css/bootstrap.min.css";
import PublicRoutes from "./routes/PublicRoutes";




function App() {
  return (
    <>
      <PublicRoutes />
    </>
  );
}

export default App;
