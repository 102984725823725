import React from "react";
import "../assets/css/shop.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { BlogAfter, PlantMix, ProAfter } from "../constant";

const PlantingMixes = () => {
  return (
    <>
      <Header />
      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Planting Mixes</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Shop Sec Start Here */}
      <section className="plant-mixes similar">
        <div className="before">
          <figure>
            <img src={BlogAfter} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="heading">
            <h2>Browse From Our Best Planting Mixes </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.{" "}
            </p>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail2">
                  <div className="pro-img">
                    <figure>
                      <img
                        src={PlantMix}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Premium Kigi Soil</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail2">
                  <div className="pro-img">
                    <figure>
                      <img
                        src={PlantMix}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Premium Kigi Soil</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail2">
                  <div className="pro-img">
                    <figure>
                      <img
                        src={PlantMix}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Premium Kigi Soil</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail2">
                  <div className="pro-img">
                    <figure>
                      <img
                       src={PlantMix}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Premium Kigi Soil</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail2">
                  <div className="pro-img">
                    <figure>
                      <img
                        src={PlantMix}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Premium Kigi Soil</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail2">
                  <div className="pro-img">
                    <figure>
                      <img
                       src={PlantMix}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Premium Kigi Soil</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail2">
                  <div className="pro-img">
                    <figure>
                      <img
                       src={PlantMix}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Premium Kigi Soil</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail2">
                  <div className="pro-img">
                    <figure>
                      <img
                        src={PlantMix}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Premium Kigi Soil</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail2">
                  <div className="pro-img">
                    <figure>
                      <img
                        src={PlantMix}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Premium Kigi Soil</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail2">
                  <div className="pro-img">
                    <figure>
                      <img
                       src={PlantMix}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Premium Kigi Soil</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail2">
                  <div className="pro-img">
                    <figure>
                      <img
                        src={PlantMix}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Premium Kigi Soil</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="product-box">
                <a href="product-detail2">
                  <div className="pro-img">
                    <figure>
                      <img
                        src={PlantMix}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Premium Kigi Soil</h4>
                    </div>
                    {/* <div class="price">
                                <h4>$250.00</h4>
                            </div> */}
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="paginatio-count">
            <ul className="pagination-list">
              <li className="pagi arrow">
                <a href="#">
                  <i className="fa fa-angle-left" aria-hidden="true" />
                </a>
              </li>
              <li className="pagi">
                <a href="#">1</a>
              </li>
              <li className="pagi active">
                <a href="#">2</a>
              </li>
              <li className="pagi">
                <a href="#">...</a>
              </li>
              <li className="pagi">
                <a href="#">12</a>
              </li>
              <li className="pagi arrow">
                <a href="#">
                  <i className="fa fa-angle-right" aria-hidden="true" />
                </a>
              </li>
            </ul>
            <p className="total-pages">12 PRODUCTS 1 - 12</p>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={ProAfter} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* Shop Sec End Here */}

      <Footer />
    </>
  );
};

export default PlantingMixes;
