import React from "react";
import "../assets/css/profile.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { BirdImg2, pointer, ProAfter, ProBefore, Product3, WishListProImg } from "../constant";
import {useNavigate} from "react-router-dom";
const Profile = () => {
    const navigate = useNavigate()
  return (
    <>
      <Header />
      {/* Profile Sec Start Here */}
      <section className="profile-sec">
        <div className="before">
          <figure>
            <img src={ProBefore} className="img-fluid" alt="" />
          </figure>
        </div>
        <div className="container">
          <div className="heading">
            <h2>My Profile</h2>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="sidebar">
                <div className="user-info">
                  <div className="img-box">
                    <figure>
                      <img src="img/profile.png" className="img-fluid" alt="" />
                    </figure>
                  </div>
                  <div className="info">
                    <h3>Stewart Cannon</h3>
                    <h5>Customer</h5>
                  </div>
                </div>
                <div
                  className="nav flex-column nav-pills me-3"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {/* First Tab Button */}
                  <button
                    className="nav-link active"
                    id="v-pills-Account-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-Account"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-Account"
                    aria-selected="true"
                  >
                    My Account{" "}
                    <img src={pointer} className="img-fluid" alt="" />
                  </button>
                  {/* First Tab Button */}
                  {/* Second Tab Button */}
                  <button
                    className="nav-link"
                    id="v-pills-Addresses-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-Addresses"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-Addresses"
                    aria-selected="false"
                  >
                    My Addresses{" "}
                    <img src={pointer} className="img-fluid" alt="" />
                  </button>
                  {/* Second Tab Button */}
                  {/* Third Tab Button */}
                  <button
                    className="nav-link"
                    id="v-pills-Donations-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-Donations"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-Donations"
                    aria-selected="false"
                  >
                    My Donations{" "}
                    <img src={pointer} className="img-fluid" alt="" />
                  </button>
                  {/* Third Tab Button */}
                  {/* Fourth Tab Button */}
                  <button
                    className="nav-link"
                    id="v-pills-Orders-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-Orders"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-Orders"
                    aria-selected="false"
                  >
                    My Orders{" "}
                    <img src={pointer} className="img-fluid" alt="" />
                  </button>
                  {/* Fourth Tab Button */}
                  {/* Fifth Tab Button */}
                  <button
                    className="nav-link"
                    id="v-pills-Wishlist-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-Wishlist"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-Wishlist"
                    aria-selected="false"
                  >
                    Wishlist{" "}
                    <img src={pointer} className="img-fluid" alt="" />
                  </button>
                  {/* Fifth Tab Button */}
                  {/* Logout Button */}
                  <button
                     onClick={()=>navigate("/")}
                    className="logout"
                  >
                    LogOut
                  </button>
                  {/* Logout Button */}
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="tab-content" id="v-pills-tabContent">
                {/* First Tab Content */}
                <div
                  className="tab-pane fade show active"
                  id="v-pills-Account"
                  role="tabpanel"
                  aria-labelledby="v-pills-Account-tab"
                >
                  <div className="tab-box first-box">
                    <div className="persnol-information box">
                      <div className="heading">
                        <h3>Personal Infromation</h3>
                        <button className="editInformation edit">
                          <i className="fa fa-pencil" aria-hidden="true" />
                          <span>Edit</span>
                        </button>
                      </div>
                      <div className="row align-items-center mr-20">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>First Name</label>
                            <input
                              type="text"
                              defaultValue="Stewart"
                              readOnly
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Last Name</label>
                            <input
                              type="text"
                              defaultValue="Cannon"
                              readOnly
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Phone</label>
                            <input
                              type="tel"
                              defaultValue="+1-234-567-890"
                              readOnly
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              type="email"
                              defaultValue="S.Cannon@gmail.com"
                              readOnly
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="persnol-information box">
                      <div className="heading">
                        <h3>Password</h3>
                        <button className="editPassword edit">
                          <i className="fa fa-pencil" aria-hidden="true" />
                          <span>Edit</span>
                        </button>
                      </div>
                      <div className="row align-items-center mr-20">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Password</label>
                            <input
                              type="password"
                              defaultValue={123456789}
                              readOnly
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-box second-box hide">
                    <div className="change-information box">
                      <div className="heading">
                        <h3>Personal Infromation</h3>
                      </div>
                      <div className="row align-items-center mr-20">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>First Name</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Last Name</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Phone</label>
                            <input type="tel" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Email</label>
                            <input type="email" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="button-group">
                            <button className="btn saveInformation">
                              SAved
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-box third-box hide">
                    <div className="change-pass box">
                      <div className="heading">
                        <h3>Change Password</h3>
                      </div>
                      <div className="row align-items-center mr-20">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Old Passwords</label>
                            <input
                              type="password"
                              defaultValue={123456789}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>New Passwords</label>
                            <input
                              type="password"
                              defaultValue={123456789}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Current Passwords</label>
                            <input
                              type="password"
                              defaultValue={123456789}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mt-4">
                            <div className="button-group">
                              <button className="btn savePassword">
                                SAved
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* First Tab Content */}
                {/* Second Tab Content */}
                <div
                  className="tab-pane fade"
                  id="v-pills-Addresses"
                  role="tabpanel"
                  aria-labelledby="v-pills-Addresses-tab"
                >
                  <div className="tab-box fourth-box">
                    <div className="my-address box">
                      <div className="heading">
                        <h3>My Address</h3>
                        {/* <button class="editAddress edit">
                                        <i class="fa fa-pencil" aria-hidden="true"></i><span>Edit</span>
                                    </button> */}
                      </div>
                      <div className="row align-items-center mr-20">
                        {/* <div class="col-md-6">
                                        <div class="form-group">
                                            <label>First Name</label>
                                            <input type="text" value="Stewart" readonly class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Last Name</label>
                                            <input type="text" value="Cannon" readonly class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Phone</label>
                                            <input type="tel" value="+1-234-567-890" readonly class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input type="email" value="S.Cannon@gmail.com" readonly class="form-control" />
                                        </div>
                                    </div> */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Address</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Suite, Apartment, etc </label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>City</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>State</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Country</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Zip Code</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="button-group mt-3">
                            <button className="btn addAddress">
                              Add new Address
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="save-address">
                        <div className="heading">
                          <h3>Saved Addresses</h3>
                        </div>
                        {/* Saved Address */}
                        <label className="container">
                          <input
                            type="radio"
                            defaultChecked="checked"
                            name="radio"
                          />
                          <span className="checkmark">
                            <div className="select-address">
                              <div className="address-detail">
                                <div className="actions">
                                  <a href="#" className="editAddress edit">
                                    <i
                                      className="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                    />
                                  </a>
                                  <a href="#">
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    />
                                  </a>
                                </div>
                                <h5>
                                  <span className="property">Country:</span>
                                  <div className="spacer" />
                                  <span className="value">United States</span>
                                </h5>
                                <h5>
                                  <span className="property">Address:</span>
                                  <div className="spacer" />
                                  <span className="value">
                                    10515 Fox Ave Fairdale, Kentucky(KY), 40118
                                  </span>
                                </h5>
                                <h5>
                                  <span className="property">Phone:</span>
                                  <div className="spacer" />
                                  <span className="value">+44-123-456-789</span>
                                </h5>
                                <h5>
                                  <span className="property">State:</span>
                                  <div className="spacer" />
                                  <span className="value">Texas</span>
                                </h5>
                                <div className="deafult">
                                  <h5>Use as default</h5>
                                  <span className="dot" />
                                </div>
                              </div>
                            </div>
                          </span>
                        </label>
                        <label className="container">
                          <input type="radio" name="radio" />
                          <span className="checkmark">
                            <div className="select-address">
                              <div className="address-detail">
                                <div className="actions">
                                  <a href="#" className="editAddress edit">
                                    <i
                                      className="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                    />
                                  </a>
                                  <a href="#">
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    />
                                  </a>
                                </div>
                                <h5>
                                  <span className="property">Country:</span>
                                  <div className="spacer" />
                                  <span className="value">United States</span>
                                </h5>
                                <h5>
                                  <span className="property">Address:</span>
                                  <div className="spacer" />
                                  <span className="value">
                                    10515 Fox Ave Fairdale, Kentucky(KY), 40118
                                  </span>
                                </h5>
                                <h5>
                                  <span className="property">Phone:</span>
                                  <div className="spacer" />
                                  <span className="value">+44-123-456-789</span>
                                </h5>
                                <h5>
                                  <span className="property">State:</span>
                                  <div className="spacer" />
                                  <span className="value">Texas</span>
                                </h5>
                                <div className="deafult">
                                  <h5>Use as default</h5>
                                  <span className="dot" />
                                </div>
                              </div>
                            </div>
                          </span>
                        </label>
                        {/* Saved Address */}
                      </div>
                    </div>
                  </div>
                  <div className="tab-box fifth-box hide">
                    <div className="change-address box">
                      <div className="heading">
                        <h3>My Address</h3>
                      </div>
                      <div className="row align-items-center mr-20">
                        {/* <div class="col-md-6">
                                        <div class="form-group">
                                            <label>First Name</label>
                                            <input type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Last Name</label>
                                            <input type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Phone</label>
                                            <input type="tel" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input type="email" class="form-control" />
                                        </div>
                                    </div> */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Address</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Suite, Apartment, etc </label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>City</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>State</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Country/Region</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Zip Code</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="button-group mt-3">
                            <button className="btn saveAddress">
                              Saved Address
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Second Tab Content */}
                {/* Third Tab Content */}
                <div
                  className="tab-pane fade"
                  id="v-pills-Donations"
                  role="tabpanel"
                  aria-labelledby="v-pills-Donations-tab"
                >
                  <div className="tab-box sixth-box">
                    <div className="donations box">
                      <div className="heading">
                        <h3>My Donations</h3>
                      </div>
                      <div className="donation-table">
                        <div className="table-reponsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Booking Campaign</th>
                                <th>Donation Date &amp; Time</th>
                                <th>Payment Status</th>
                                <th>Total</th>
                                <th>Details</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>#1084598423154</td>
                                <td>29 Dec 22 | 17:30 Am</td>
                                <td>
                                  <div className="status">
                                    <span>Paid</span>
                                    <figure>
                                      <img
                                        src="img/approved.png"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </figure>
                                  </div>
                                </td>
                                <td>$590.00</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#donationDetail"
                                  >
                                    View Detail
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>#1084598423154</td>
                                <td>29 Dec 22 | 17:30 Am</td>
                                <td>
                                  <div className="status">
                                    <span>Paid</span>
                                    <figure>
                                      <img
                                        src="img/approved.png"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </figure>
                                  </div>
                                </td>
                                <td>$590.00</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#donationDetail"
                                  >
                                    View Detail
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>#1084598423154</td>
                                <td>29 Dec 22 | 17:30 Am</td>
                                <td>
                                  <div className="status">
                                    <span>Paid</span>
                                    <figure>
                                      <img
                                        src="img/approved.png"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </figure>
                                  </div>
                                </td>
                                <td>$590.00</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#donationDetail"
                                  >
                                    View Detail
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>#1084598423154</td>
                                <td>29 Dec 22 | 17:30 Am</td>
                                <td>
                                  <div className="status">
                                    <span>Paid</span>
                                    <figure>
                                      <img
                                        src="img/approved.png"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </figure>
                                  </div>
                                </td>
                                <td>$590.00</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#donationDetail"
                                  >
                                    View Detail
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>#1084598423154</td>
                                <td>29 Dec 22 | 17:30 Am</td>
                                <td>
                                  <div className="status">
                                    <span>Paid</span>
                                    <figure>
                                      <img
                                        src="img/approved.png"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </figure>
                                  </div>
                                </td>
                                <td>$590.00</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#donationDetail"
                                  >
                                    View Detail
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="paginatio-count">
                          <ul className="pagination-list">
                            <li className="pagi arrow">
                              <a href="#">
                                <i
                                  className="fa fa-angle-left"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li className="pagi">
                              <a href="#">1</a>
                            </li>
                            <li className="pagi active">
                              <a href="#">2</a>
                            </li>
                            <li className="pagi">
                              <a href="#">...</a>
                            </li>
                            <li className="pagi">
                              <a href="#">12</a>
                            </li>
                            <li className="pagi arrow">
                              <a href="#">
                                <i
                                  className="fa fa-angle-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Third Tab Content */}
                {/* Fourth Tab Content */}
                <div
                  className="tab-pane fade"
                  id="v-pills-Orders"
                  role="tabpanel"
                  aria-labelledby="v-pills-Orders-tab"
                >
                  <div className="tab-box sixth-box">
                    <div className="donations box">
                      <div className="heading">
                        <h3>My Orders</h3>
                      </div>
                      <div className="donation-table">
                        <div className="table-reponsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>OrderNo</th>
                                <th>Order Date</th>
                                <th>Status</th>
                                <th>Payment Status</th>
                                <th>Total</th>
                                <th>Details</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>#1084598423154</td>
                                <td>04-12-21</td>
                                <td>Confirm</td>
                                <td>
                                  <div className="status">
                                    <span>Paid</span>
                                    <figure>
                                      <img
                                        src="img/approved.png"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </figure>
                                  </div>
                                </td>
                                <td>$590.00</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#orderDetail"
                                  >
                                    View Detail
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>#1084598423154</td>
                                <td>04-12-21</td>
                                <td>Confirm</td>
                                <td>
                                  <div className="status">
                                    <span>Paid</span>
                                    <figure>
                                      <img
                                        src="img/approved.png"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </figure>
                                  </div>
                                </td>
                                <td>$590.00</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#orderDetail"
                                  >
                                    View Detail
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>#1084598423154</td>
                                <td>04-12-21</td>
                                <td>Confirm</td>
                                <td>
                                  <div className="status">
                                    <span>Paid</span>
                                    <figure>
                                      <img
                                        src="img/approved.png"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </figure>
                                  </div>
                                </td>
                                <td>$590.00</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#orderDetail"
                                  >
                                    View Detail
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>#1084598423154</td>
                                <td>04-12-21</td>
                                <td>Confirm</td>
                                <td>
                                  <div className="status">
                                    <span>Paid</span>
                                    <figure>
                                      <img
                                        src="img/approved.png"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </figure>
                                  </div>
                                </td>
                                <td>$590.00</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#orderDetail"
                                  >
                                    View Detail
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>#1084598423154</td>
                                <td>04-12-21</td>
                                <td>Confirm</td>
                                <td>
                                  <div className="status">
                                    <span>Paid</span>
                                    <figure>
                                      <img
                                        src="img/approved.png"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </figure>
                                  </div>
                                </td>
                                <td>$590.00</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#orderDetail"
                                  >
                                    View Detail
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="paginatio-count">
                          <ul className="pagination-list">
                            <li className="pagi arrow">
                              <a href="#">
                                <i
                                  className="fa fa-angle-left"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                            <li className="pagi">
                              <a href="#">1</a>
                            </li>
                            <li className="pagi active">
                              <a href="#">2</a>
                            </li>
                            <li className="pagi">
                              <a href="#">...</a>
                            </li>
                            <li className="pagi">
                              <a href="#">12</a>
                            </li>
                            <li className="pagi arrow">
                              <a href="#">
                                <i
                                  className="fa fa-angle-right"
                                  aria-hidden="true"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Fourth Tab Content */}
                {/* Fifth Tab Content */}
                <div
                  className="tab-pane fade"
                  id="v-pills-Wishlist"
                  role="tabpanel"
                  aria-labelledby="v-pills-Wishlist-tab"
                >
                  <div className="tab-box wishlist-box">
                    <div className="wishlist-box box">
                      <div className="heading">
                        <h3>Wishlist</h3>
                      </div>
                      <div className="table-resposnive">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th />
                              <th>Product Name</th>
                              <th>Detail</th>
                              <th>PRICE</th>
                              {/* <th>SUBTOTAL</th> */}
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <figure>
                                  <img
                                    src="img/wishlist-pro.png"
                                    className="img-fluid"
                                    alt=""
                                  />
                                </figure>
                              </td>
                              <td>
                                <h4 className="pro-name">Whisper Coneflower</h4>
                              </td>
                              <td>
                                <h4 className="variation-type">Color: Green</h4>
                                <h4 className="variation-type">
                                  Leaf Type: Lorem
                                </h4>
                              </td>
                              <td>
                                <h4 className="price">$58.07 </h4>
                              </td>
                              {/* <td>
                                                    <h4 class="subtotal">$158.07</h4>
                                                </td> */}
                              <td>
                                <div className="delete">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={26}
                                    height={26}
                                    fill="#EF5D61"
                                    className="bi bi-x"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                  </svg>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <figure>
                                  <img
                                    src={WishListProImg}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </figure>
                              </td>
                              <td>
                                <h4 className="pro-name">Whisper Coneflower</h4>
                              </td>
                              <td>
                                <h4 className="variation-type">Color: Green</h4>
                                <h4 className="variation-type">
                                  Leaf Type: Lorem
                                </h4>
                              </td>
                              <td>
                                <h4 className="price">$58.07 </h4>
                              </td>
                              {/* <td>
                                                    <h4 class="subtotal">$158.07</h4>
                                                </td> */}
                              <td>
                                <div className="delete">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={26}
                                    height={26}
                                    fill="#EF5D61"
                                    className="bi bi-x"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                  </svg>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <figure>
                                  <img
                                    src={WishListProImg}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </figure>
                              </td>
                              <td>
                                <h4 className="pro-name">Whisper Coneflower</h4>
                              </td>
                              <td>
                                <h4 className="variation-type">Color: Green</h4>
                                <h4 className="variation-type">
                                  Leaf Type: Lorem
                                </h4>
                              </td>
                              <td>
                                <h4 className="price">$58.07 </h4>
                              </td>
                              {/* <td>
                                                    <h4 class="subtotal">$158.07</h4>
                                                </td> */}
                              <td>
                                <div className="delete">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={26}
                                    height={26}
                                    fill="#EF5D61"
                                    className="bi bi-x"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                  </svg>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <figure>
                                  <img
                                    src={WishListProImg}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </figure>
                              </td>
                              <td>
                                <h4 className="pro-name">Whisper Coneflower</h4>
                              </td>
                              <td>
                                <h4 className="variation-type">Color: Green</h4>
                                <h4 className="variation-type">
                                  Leaf Type: Lorem
                                </h4>
                              </td>
                              <td>
                                <h4 className="price">$58.07 </h4>
                              </td>
                              {/* <td>
                                                    <h4 class="subtotal">$158.07</h4>
                                                </td> */}
                              <td>
                                <div className="delete">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={26}
                                    height={26}
                                    fill="#EF5D61"
                                    className="bi bi-x"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                  </svg>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <figure>
                                  <img
                                    src={WishListProImg}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </figure>
                              </td>
                              <td>
                                <h4 className="pro-name">Whisper Coneflower</h4>
                              </td>
                              <td>
                                <h4 className="variation-type">Color: Green</h4>
                                <h4 className="variation-type">
                                  Leaf Type: Lorem
                                </h4>
                              </td>
                              <td>
                                <h4 className="price">$58.07 </h4>
                              </td>
                              {/* <td>
                                                    <h4 class="subtotal">$158.07</h4>
                                                </td> */}
                              <td>
                                <div className="delete">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={26}
                                    height={26}
                                    fill="#EF5D61"
                                    className="bi bi-x"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                  </svg>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="paginatio-count">
                        <ul className="pagination-list">
                          <li className="pagi arrow">
                            <a href="#">
                              <i
                                className="fa fa-angle-left"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                          <li className="pagi">
                            <a href="#">1</a>
                          </li>
                          <li className="pagi active">
                            <a href="#">2</a>
                          </li>
                          <li className="pagi">
                            <a href="#">...</a>
                          </li>
                          <li className="pagi">
                            <a href="#">12</a>
                          </li>
                          <li className="pagi arrow">
                            <a href="#">
                              <i
                                className="fa fa-angle-right"
                                aria-hidden="true"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Fifth Tab Content */}
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={ProAfter} className="img-fluid" alt="" />
          </figure>
        </div>
      </section>
      {/* Profile Sec End Here */}
      {/* My Order Popup */}
      <div
        className="modal fade"
        id="orderDetail"
        tabIndex={-1}
        aria-labelledby="orderDetailLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="heading">
                <h2>Order Details</h2>
              </div>
              <div className="all-orders">
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img
                        src={Product3}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Nutient</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>
                        <label>Quantity</label>(2)
                      </span>
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img
                        src={Product3}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Nutient</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>
                        <label>Quantity</label>(2)
                      </span>
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img
                        src={Product3}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Nutient</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>
                        <label>Quantity</label>(2)
                      </span>
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img
                        src={Product3}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Nutient</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>
                        <label>Quantity</label>(2)
                      </span>
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
              </div>
              <ul className="order-summary">
                <li>
                  <span>Sub Total :</span>
                  <span>$ 147.00</span>
                </li>
                <li>
                  <span>Disscount Code Applied :</span>
                  <span>-$ 14.00</span>
                </li>
                <li>
                  <span>Shipping Cost :</span>
                  <span>$ 69.00</span>
                </li>
                <li>
                  <span>Total Amount :</span>
                  <span>$ 150.00</span>
                </li>
              </ul>
              <div className="button-group">
                <a href="shop" className="btn">
                  Continue Shopping
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* My Order Popup */}
      {/* Donation Detail Popup */}
      <div
        className="modal fade"
        id="donationDetail"
        tabIndex={-1}
        aria-labelledby="donationDetailLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="heading">
                <h2>Donation Details</h2>
              </div>
              <div className="all-orders">
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img src={BirdImg2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Kigi bird</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>
                        <span>Paid</span>
                      </span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img src={BirdImg2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Kigi bird</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>
                        <span>Paid</span>
                      </span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img src={BirdImg2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Kigi bird</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>
                        <span>Paid</span>
                      </span>
                    </h5>
                  </div>
                </div>
                <div className="order-detail">
                  <div className="img-box">
                    <figure>
                      <img src={BirdImg2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="details">
                    <h5 className="name">Kigi bird</h5>
                    <h5 className="price">
                      <span className="pro-name">Price: $590.00</span>
                      <span className="order-no">order no: #1084598423154</span>
                    </h5>
                    <h5 className="status">
                      <span>Paid</span>
                    </h5>
                  </div>
                </div>
              </div>
              <ul className="order-summary">
                <li>
                  <span>Sub Total :</span>
                  <span>$ 147.00</span>
                </li>
                <li>
                  <span>Total Amount :</span>
                  <span>$ 150.00</span>
                </li>
              </ul>
              <div className="button-group">
                <a href="kigi-birds" className="btn">
                  Continue
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Donation Detail Popup */}
      <Footer />
    </>
  );
};

export default Profile;
