import React from "react";
import { ArrowImg, ProAfter, ProBefore } from "../constant";
import "../assets/css/login.css";
import Header from "../component/Header";

const Forgot = () => {
  return (
    <>
      <Header />
      <section className="login">
        <div className="before">
          <figure><img src={ProBefore} className="img-fluid" alt=""/></figure>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <div className="auth-box">
                <div className="heading">
                  <h2>Forgot Password</h2>
                  <h4>Write Your email to <span className="bld">Recover Account</span></h4>
                </div>
                <form action="change-pass">
                  <div className="form-group">
                    <label>Your Email</label>
                    <input type="email" className="form-control" />
                  </div>
                  <button className="btn" type="submit"><span>Submit</span> <img src={ArrowImg} className="img-fluid" alt=""/></button>
                </form>
              </div>
            </div>
            <div className="col-md-3" />
          </div>
        </div>
        <div className="after">
          <figure><img src={ProAfter} className="img-fluid"alt="" /></figure>
        </div>
      </section>

    </>
  );
};

export default Forgot;
