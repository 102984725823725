import { useEffect } from "react";
import "../App.css";
// import { useEffect } from "react";

function Chart(props) {
  console.log("propsssss here", props);
  useEffect(() => {
    /* SETTINGS
     ************************************************** */
    const settings = {
      canvasId:  props.data, // ID of canvas DOM element
      fillAmount: 80, // Actual amount to fill donut
      baseAmount: 100, // Max amount for donut
      fillColor: "red", // Color of active donut
      baseColor: "#EBF5FB", // Color of base donut
      lineWidth: 50, // Thickness of donut
      fontSize: "80px",
      fontFace: "Arial",
    };

    /* ANIMATION STATE
     ************************************************** */
    const state = {
      curAmount: 0,
      curDegrees: 0,
    };

    /* CANVAS
     ************************************************** */
    const canvas = document.getElementById(settings.canvasId);
    canvas.halfWidth = canvas.width / 2;
    canvas.halfHeight = canvas.height / 2;

    const ctx = canvas.getContext("2d");

    /* ANIMATE
     ************************************************** */
    animate();

    function animate() {
      update();
      draw();

      if (state.curAmount < settings.fillAmount) {
        requestAnimationFrame(animate);
      }
    }

    /* UPDATE
     ************************************************** */
    function update() {
      state.curAmount += 1;
      state.curDegrees = (state.curAmount / settings.baseAmount) * 360;
    }

    /* DRAW
     ************************************************** */
    function draw() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw donuts
      drawArc(0, 360, settings.baseColor);
      drawArc(-90, state.curDegrees - 90, settings.fillColor);

      // Draw text amount
      drawText(state.curAmount);
    }

    function drawArc(startDegrees, endDegrees, lineColor) {
      ctx.lineWidth = settings.lineWidth;
      ctx.strokeStyle = lineColor;

      ctx.beginPath();
      ctx.arc(
        canvas.halfWidth,
        canvas.halfHeight,
        // Offset line width to stop trimming at canvas edge
        canvas.halfWidth - settings.lineWidth / 2,
        degreesToRadians(startDegrees),
        degreesToRadians(endDegrees)
      );
      ctx.stroke();
    }

    function drawText(text) {
      ctx.fillStyle = settings.fillColor;
      ctx.font = "bold " + settings.fontSize + " " + settings.fontFace;
      ctx.textAlign = "center";
      ctx.fillText(
        text,
        canvas.halfWidth,
        canvas.halfHeight + parseInt(settings.fontSize) / 3
      );
    }

    /* HELPERS
     ************************************************** */
    function degreesToRadians(degrees) {
      return (degrees * Math.PI) / 180;
    }
  }, []);
  return (
    <>
      <div id="canvas-wrap">
        <canvas
          id={props.data}
          width="300"
          height="300"
          className="change"
        ></canvas>
      </div>
    </>
  );
}

export default Chart;
