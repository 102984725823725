import Home from "../pages/Home";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import Forgot from "../component/Forgot";
import WishList from "../pages/WishList";
import Shop from "../pages/Shop";
import Nutrients from "../pages/Nutrients";
import KigiBirds from "../pages/KigiBirds";
import PlantingMixes from "../pages/PlantingMixes";
import PremiumProduct from "../pages/PremiumProduct";
import Donation from "../pages/Donation";
import Contact from "../pages/Contact";
import Cart from "../pages/Cart";
import ProductDetail from "../pages/ProductDetail";
import ProductDetail2 from "../pages/ProductDetail2";
import ProductDetail3 from "../pages/ProductDetail3";
import ProductDetail4 from "../pages/ProductDetail4";
import BirdDetail from "../pages/BirdDetail";
import GiftCertificate from "../pages/GiftCertificate";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import Campaign from "../pages/Campaign";
import About from "../pages/About";
import Gallery from "../pages/Gallery";
import Faqs from "../pages/Faqs";
import Shipping from "../pages/Shipping";
import Videos from "../pages/Videos";
import ThankYou from "../pages/ThankYou";
import ChangePass from "../pages/ChangePass";
import Profile from "../pages/Profile";
import Checkout from "../pages/Checkout";
import Donate from "../pages/Donate";
import Chart from "../pages/Chart";

function PublicRoutes() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Signup />} />
          <Route path="forgot" element={<Forgot />} />
          <Route path="wishlist" element={<WishList />} />
          <Route path="shop" element={<Shop />} />
          <Route path="nutrients" element={<Nutrients />} />
          <Route path="kigi-birds" element={<KigiBirds />} />
          <Route path="planting-mixes" element={<PlantingMixes />} />
          <Route path="premium-product" element={<PremiumProduct />} />
          <Route path="donation" element={<Donation />} />
          <Route path="contact" element={<Contact />} />
          <Route path="cart" element={<Cart />} />
          <Route path="product-detail" element={<ProductDetail />} />
          <Route path="product-detail2" element={<ProductDetail2 />} />
          <Route path="product-detail3" element={<ProductDetail3 />} />
          <Route path="product-detail4" element={<ProductDetail4 />} />
          <Route path="bird-detail" element={<BirdDetail />} />
          <Route path="gift-certificate" element={<GiftCertificate />} />
          <Route path="blogs" element={<Blog />} />
          <Route path="blog-detail" element={<BlogDetails />} />
          <Route path="campaign" element={<Campaign />} />
          <Route path="about" element={<About />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="faqs" element={<Faqs />} />
          <Route path="shipping" element={<Shipping />} />
          <Route path="videos" element={<Videos />} />
          <Route path="thank-you" element={<ThankYou />} />
          <Route path="change-pass" element={<ChangePass />} />
          <Route path="profile" element={<Profile />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="donate" element={<Donate />} />
          <Route path="chart" element={<Chart />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default PublicRoutes;
