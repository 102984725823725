import React from "react";
import "../assets/css/donate.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {
  ArrowImg,
  pymnt1,
  pymnt2,
  pymnt3,
  pymnt4,
  pymnt5,
  SmallAtferImg,
  SmallBeforeImg,
} from "../constant";

const Donate = () => {
  return (
    <>
      <Header />
      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Donate Now</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Donate Now Sec Start Here */}
      <section className="donate_now">
        <div className="before">
          <figure>
            <img src={SmallAtferImg} className="img-fluid" alt="" />
          </figure>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3" />
            <div className="col-lg-6">
              <form action="thank-you">
                <div className="heading">
                  <h2>Donate Now</h2>
                </div>
                <div className="title">
                  <h2>Your Donation</h2>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <label className="donate_container">
                      <input type="radio" hidden name="donate-price" />
                      <span className="checkmark">
                        <h4>$ 50.00</h4>
                      </span>
                    </label>
                  </div>
                  <div className="col-md-4">
                    <label className="donate_container">
                      <input
                        type="radio"
                        defaultChecked
                        hidden
                        name="donate-price"
                      />
                      <span className="checkmark">
                        <h4>$ 100.00</h4>
                      </span>
                    </label>
                  </div>
                  <div className="col-md-4">
                    <label className="donate_container">
                      <input type="radio" hidden name="donate-price" />
                      <span className="checkmark">
                        <h4>$ 150.00</h4>
                      </span>
                    </label>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Custom Amount</label>
                      <input
                        type="text"
                        placeholder={0.0}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="title">
                      <h2>Details</h2>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>First Name</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Last Name</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Address</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>City</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>State</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Post Code</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Country</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="anonymously"
                        className="form-check-input"
                      />
                      <label htmlFor="anonymously">
                        I would like to make my donation anonymously.
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="title">
                      <h2>Payment</h2>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Write Your Card Number"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="card-type">
                      <label className="pymnt_container">
                        <input
                          type="radio"
                          hidden
                          defaultChecked="checked"
                          name="radio"
                        />
                        <span className="checkmark">
                          <figure>
                            <img src={pymnt1} className="img-fluid" alt="" />
                          </figure>
                        </span>
                      </label>
                      <label className="pymnt_container">
                        <input type="radio" hidden name="radio" />
                        <span className="checkmark">
                          <figure>
                            <img src={pymnt2} className="img-fluid" alt="" />
                          </figure>
                        </span>
                      </label>
                      <label className="pymnt_container">
                        <input type="radio" hidden name="radio" />
                        <span className="checkmark">
                          <figure>
                            <img src={pymnt3} className="img-fluid" alt="" />
                          </figure>
                        </span>
                      </label>
                      <label className="pymnt_container">
                        <input type="radio" hidden name="radio" />
                        <span className="checkmark">
                          <figure>
                            <img src={pymnt4} className="img-fluid" alt="" />
                          </figure>
                        </span>
                      </label>
                      <label className="pymnt_container">
                        <input type="radio" hidden name="radio" />
                        <span className="checkmark">
                          <figure>
                            <img src={pymnt5} className="img-fluid" alt="" />
                          </figure>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="buttong-group">
                      <button className="btn" type="submit">
                        Donate Now{" "}
                        <img src={ArrowImg} className="img-fluid" alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-3" />
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={SmallBeforeImg} className="img-fluid" alt="" />
          </figure>
        </div>
      </section>
      {/* Donate Now Sec End Here */}
      <Footer />
    </>
  );
};

export default Donate;
