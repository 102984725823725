import React from "react";
import "../assets/css/cart.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { CartProImg } from "../constant";

const Cart = () => {
  return (
    <>
      <Header />
      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Shopping Cart</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Cart Sec Start Here */}
      <section className="cart_sec">
        <div className="container">
          <div className="table-resposnive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th />
                  <th>Product Name</th>
                  <th>Detail</th>
                  <th>Delivery date</th>
                  <th>PRICE</th>
                  <th className="quantityTh">QUANTITY</th>
                  <th>SUBTOTAL</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <figure>
                      <img src={CartProImg} className="img-fluid" alt="" />
                    </figure>
                  </td>
                  <td>
                    <h4 className="pro-name">Whisper Coneflower</h4>
                  </td>
                  <td>
                    <h4 className="variation-type">Color: Green</h4>
                    <h4 className="variation-type">Leaf Type: Lorem</h4>
                  </td>
                  <td>
                    <select name id>
                      <option value="winjf">
                        Winter (January - February) Southern &amp; West Coast +
                        HI
                      </option>
                      <option value="winjf">
                        Early Spring (March - April) All Lower 48 States + HI
                      </option>
                      <option value="winjf">
                        Late Spring (May - June) Northern &amp; West Coast
                        States + AK
                      </option>
                      <option value="winjf">
                        Fall (September - November) All Lower 48 States + HI
                      </option>
                    </select>
                    {/* <h4 class="date">Winter (January - February) </h4> */}
                  </td>
                  <td>
                    <h4 className="price">$58.07 </h4>
                  </td>
                  <td className="quantityTh">
                    <div className="quantity">
                      <input
                        type="number"
                        defaultValue="02"
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <h4 className="subtotal">$158.07</h4>
                  </td>
                  <td>
                    <div className="delete">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={26}
                        height={26}
                        fill="#EF5D61"
                        className="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <figure>
                      <img src={CartProImg} className="img-fluid" alt="" />
                    </figure>
                  </td>
                  <td>
                    <h4 className="pro-name">Whisper Coneflower</h4>
                  </td>
                  <td>
                    <h4 className="variation-type">Color: Green</h4>
                    <h4 className="variation-type">Leaf Type: Lorem</h4>
                  </td>
                  <td>
                    <select name id>
                      <option value="winjf">
                        Winter (January - February) Southern &amp; West Coast +
                        HI
                      </option>
                      <option value="winjf">
                        Early Spring (March - April) All Lower 48 States + HI
                      </option>
                      <option value="winjf">
                        Late Spring (May - June) Northern &amp; West Coast
                        States + AK
                      </option>
                      <option value="winjf">
                        Fall (September - November) All Lower 48 States + HI
                      </option>
                    </select>
                    {/* <h4 class="date">Winter (January - February) </h4> */}
                  </td>
                  <td>
                    <h4 className="price">$58.07 </h4>
                  </td>
                  <td className="quantityTh">
                    <div className="quantity">
                      <input
                        type="number"
                        defaultValue="02"
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <h4 className="subtotal">$158.07</h4>
                  </td>
                  <td>
                    <div className="delete">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={26}
                        height={26}
                        fill="#EF5D61"
                        className="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <figure>
                      <img src={CartProImg} className="img-fluid" alt="" />
                    </figure>
                  </td>
                  <td>
                    <h4 className="pro-name">Whisper Coneflower</h4>
                  </td>
                  <td>
                    <h4 className="variation-type">Color: Green</h4>
                    <h4 className="variation-type">Leaf Type: Lorem</h4>
                  </td>
                  <td>
                    <select name id>
                      <option value="winjf">
                        Winter (January - February) Southern &amp; West Coast +
                        HI
                      </option>
                      <option value="winjf">
                        Early Spring (March - April) All Lower 48 States + HI
                      </option>
                      <option value="winjf">
                        Late Spring (May - June) Northern &amp; West Coast
                        States + AK
                      </option>
                      <option value="winjf">
                        Fall (September - November) All Lower 48 States + HI
                      </option>
                    </select>
                    {/* <h4 class="date">Winter (January - February) </h4> */}
                  </td>
                  <td>
                    <h4 className="price">$58.07 </h4>
                  </td>
                  <td className="quantityTh">
                    <div className="quantity">
                      <input
                        type="number"
                        defaultValue="02"
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <h4 className="subtotal">$158.07</h4>
                  </td>
                  <td>
                    <div className="delete">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={26}
                        height={26}
                        fill="#EF5D61"
                        className="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <figure>
                      <img src={CartProImg} className="img-fluid" alt="" />
                    </figure>
                  </td>
                  <td>
                    <h4 className="pro-name">Whisper Coneflower</h4>
                  </td>
                  <td>
                    <h4 className="variation-type">Color: Green</h4>
                    <h4 className="variation-type">Leaf Type: Lorem</h4>
                  </td>
                  <td>
                    <select name id>
                      <option value="winjf">
                        Winter (January - February) Southern &amp; West Coast +
                        HI
                      </option>
                      <option value="winjf">
                        Early Spring (March - April) All Lower 48 States + HI
                      </option>
                      <option value="winjf">
                        Late Spring (May - June) Northern &amp; West Coast
                        States + AK
                      </option>
                      <option value="winjf">
                        Fall (September - November) All Lower 48 States + HI
                      </option>
                    </select>
                    {/* <h4 class="date">Winter (January - February) </h4> */}
                  </td>
                  <td>
                    <h4 className="price">$58.07 </h4>
                  </td>
                  <td className="quantityTh">
                    <div className="quantity">
                      <input
                        type="number"
                        defaultValue="02"
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <h4 className="subtotal">$158.07</h4>
                  </td>
                  <td>
                    <div className="delete">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={26}
                        height={26}
                        fill="#EF5D61"
                        className="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <figure>
                      <img src={CartProImg} className="img-fluid" alt="" />
                    </figure>
                  </td>
                  <td>
                    <h4 className="pro-name">Whisper Coneflower</h4>
                  </td>
                  <td>
                    <h4 className="variation-type">Color: Green</h4>
                    <h4 className="variation-type">Leaf Type: Lorem</h4>
                  </td>
                  <td>
                    <select name id>
                      <option value="winjf">
                        Winter (January - February) Southern &amp; West Coast +
                        HI
                      </option>
                      <option value="winjf">
                        Early Spring (March - April) All Lower 48 States + HI
                      </option>
                      <option value="winjf">
                        Late Spring (May - June) Northern &amp; West Coast
                        States + AK
                      </option>
                      <option value="winjf">
                        Fall (September - November) All Lower 48 States + HI
                      </option>
                    </select>
                    {/* <h4 class="date">Winter (January - February) </h4> */}
                  </td>
                  <td>
                    <h4 className="price">$58.07 </h4>
                  </td>
                  <td className="quantityTh">
                    <div className="quantity">
                      <input
                        type="number"
                        defaultValue="02"
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <h4 className="subtotal">$158.07</h4>
                  </td>
                  <td>
                    <div className="delete">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={26}
                        height={26}
                        fill="#EF5D61"
                        className="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <figure>
                      <img src={CartProImg} className="img-fluid" alt="" />
                    </figure>
                  </td>
                  <td>
                    <h4 className="pro-name">Whisper Coneflower</h4>
                  </td>
                  <td>
                    <h4 className="variation-type">Color: Green</h4>
                    <h4 className="variation-type">Leaf Type: Lorem</h4>
                  </td>
                  <td>
                    <select name id>
                      <option value="winjf">
                        Winter (January - February) Southern &amp; West Coast +
                        HI
                      </option>
                      <option value="winjf">
                        Early Spring (March - April) All Lower 48 States + HI
                      </option>
                      <option value="winjf">
                        Late Spring (May - June) Northern &amp; West Coast
                        States + AK
                      </option>
                      <option value="winjf">
                        Fall (September - November) All Lower 48 States + HI
                      </option>
                    </select>
                    {/* <h4 class="date">Winter (January - February) </h4> */}
                  </td>
                  <td>
                    <h4 className="price">$58.07 </h4>
                  </td>
                  <td className="quantityTh">
                    <div className="quantity">
                      <input
                        type="number"
                        defaultValue="02"
                        className="form-control"
                      />
                    </div>
                  </td>
                  <td>
                    <h4 className="subtotal">$158.07</h4>
                  </td>
                  <td>
                    <div className="delete">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={26}
                        height={26}
                        fill="#EF5D61"
                        className="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="bottm-bar">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="coupon">
                    <label>Gift Certificate code</label>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Enter your coupon code if you have one."
                        className="form-control"
                      />
                      <button className="btn" type="submit">
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="button-group">
                    <a href="shop" className="btn">
                      Continue Shopping
                    </a>
                    <a href="#" className="btn">
                      Clear Cart
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="amount-calculation">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="all-amount">
                    <p className="subtotal">
                      <span className="property">Subtotal</span>
                      <span className="value">$ 700.00</span>
                    </p>
                    <p className="shipping">
                      <span className="property">Estimated Shipping Cost</span>
                      <span className="value">$ Free Shipping</span>
                    </p>
                    <p className="total">
                      <span className="property">TOTAL COST </span>
                      <span className="value">$ 700.15</span>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="button-group">
                    <a href="checkout" className="btn">
                      Continue To checkout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Cart Sec End Here */}
      <Footer />
    </>
  );
};

export default Cart;
