import React from "react";
import { WishListProImg } from "../constant";
import "../assets/css/wishlist.css";
import Header from "../component/Header";
import Footer from "../component/Footer";

const WishList = () => {
  return (
    <>
      <Header />
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Wishlist</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Wishlist Sec Start Here */}
      <section className="wishlist_sec">
        <div className="container">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th />
                  <th />
                  <th className="name">Product Name</th>
                  <th className="Detail">Detail</th>
                  <th className="price">PRICE</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={26}
                      height={26}
                      fill="#EF5D61"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </td>
                  <td>
                    <figure>
                      <img
                        src={WishListProImg}
                        className="img-fluid"
                        alt="123"
                      />
                    </figure>
                  </td>
                  <td>
                    <h5>Whisper Coneflower</h5>
                  </td>
                  <td>
                    <div className="variation">
                      <h5>Color: Green</h5>
                      <h5>Leaf Type: Lorem</h5>
                    </div>
                  </td>
                  <td>
                    <h5 className="price">$58.07 </h5>
                  </td>
                  <td>
                    <div className="button-group">
                      <button className="btn">Add to cart</button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={26}
                      height={26}
                      fill="#EF5D61"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </td>
                  <td>
                    <figure>
                      <img
                        src={WishListProImg}
                        className="img-fluid"
                        alt="123"
                      />
                    </figure>
                  </td>
                  <td>
                    <h5>Whisper Coneflower</h5>
                  </td>
                  <td>
                    <div className="variation">
                      <h5>Color: Green</h5>
                      <h5>Leaf Type: Lorem</h5>
                    </div>
                  </td>
                  <td>
                    <h5 className="price">$58.07 </h5>
                  </td>
                  <td>
                    <div className="button-group">
                      <button className="btn">Add to cart</button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={26}
                      height={26}
                      fill="#EF5D61"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </td>
                  <td>
                    <figure>
                      <img
                        src={WishListProImg}
                        className="img-fluid"
                        alt="123"
                      />
                    </figure>
                  </td>
                  <td>
                    <h5>Whisper Coneflower</h5>
                  </td>
                  <td>
                    <div className="variation">
                      <h5>Color: Green</h5>
                      <h5>Leaf Type: Lorem</h5>
                    </div>
                  </td>
                  <td>
                    <h5 className="price">$58.07 </h5>
                  </td>
                  <td>
                    <div className="button-group">
                      <button className="btn">Add to cart</button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={26}
                      height={26}
                      fill="#EF5D61"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </td>
                  <td>
                    <figure>
                      <img
                        src={WishListProImg}
                        className="img-fluid"
                        alt="123"
                      />
                    </figure>
                  </td>
                  <td>
                    <h5>Whisper Coneflower</h5>
                  </td>
                  <td>
                    <div className="variation">
                      <h5>Color: Green</h5>
                      <h5>Leaf Type: Lorem</h5>
                    </div>
                  </td>
                  <td>
                    <h5 className="price">$58.07 </h5>
                  </td>
                  <td>
                    <div className="button-group">
                      <button className="btn">Add to cart</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      {/* Wishlist Sec End Here */}
      <Footer />
    </>
  );
};

export default WishList;
