import React, { useState } from "react";
import "../assets/css/campaign.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import ChartPie from "@garvae/react-pie-chart";
import {
  ArrowImg,
  campaign1,
  campaign2,
  ourDonor1,
  ourDonor2,
  ourDonor3,
  ourDonor4,
  SmallAtferImg,
  SmallBeforeImg,
} from "../constant";

const DATA = [
  {
    color: "#c23127",
    order: 1,
    segmentId: "001",
    value: 500000,
    donutHoleRadius: "70%",
  },
  {
    color: "#fff",
    order: 2,
    segmentId: "002",
    value: 400000,
    donutHoleRadius: "30%",
  },
];

const Campaign = () => {
  const ref = React.useRef(null);
  return (
    <>
      <Header />
      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Campaign</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Campaign Sec Start Here */}
      <section className="campaign">
        <div className="before">
          <figure>
            <img src={SmallAtferImg} className="img-fluid" alt="" />
          </figure>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="img_box">
                <figure>
                  <img src={campaign1} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-wrapper">
                <h2>Save Nature</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident.
                </p>
             
                <div
                  ref={ref}
                  // here we use parent container size to resize chart depends on it
                 className="chart-container"
                >
                  <ChartPie data={DATA} parentRef={ref} className="chart-main"/>
                </div>
                <div className="for-chart">
                  <div className="left">
                    <div className="chart">
                      <div className="goal">
                        <h5>Goal</h5>
                        <h4>$500,170</h4>
                      </div>
                      <canvas id="myChart" />
                      <div className="donate">
                        <h5>Donated</h5>
                        <h4>$447,747</h4>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#donors"
                    >
                      <div className="total">
                        <div className="box">
                          <h4>$335,170</h4>
                          <h5>Goal</h5>
                        </div>
                        <div className="box">
                          <h4>$447,747</h4>
                          <h5>Donated</h5>
                        </div>
                        <div className="box">
                          <h4>49</h4>
                          <h5>Donors</h5>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="button-group">
                  <a href="donate" className="btn">
                    <span>donate nOW</span>{" "}
                    <img src={ArrowImg} className="img-fluid" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center scnd_row mt-5">
            <div className="col-lg-6">
              <div className="content-wrapper">
                <h2>Deserunt mollit anim id est laborum.</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident.
                  <br />
                  <br />
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident.
                  <br />
                  <br />
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                {/* <div class="button-group">
                        <a href="#" class="btn">Submit review <img src="img/arrow.png" class="img-fluid"></a>
                    </div> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img_box">
                <figure>
                  <img src={campaign2} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={SmallBeforeImg} className="img-fluid" alt="" />
          </figure>
        </div>
      </section>
      {/* Campaign Sec End Here */}
      {/* Our Donor Sec Start Here */}
      <section className="our_donor">
        <div className="container">
          <div className="heading">
            <h2>Our Donors</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="img-box">
                <figure>
                  <img src={ourDonor1} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="img-box">
                <figure>
                  <img src={ourDonor2} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="img-box">
                <figure>
                  <img src={ourDonor3} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="img-box">
                <figure>
                  <img src={ourDonor4} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
          </div>
          <div className="button-group">
            <a href="donate" className="btn">
              Donate Now
            </a>
          </div>
        </div>
      </section>
      {/* Our Donor Sec End Here */}
      {/* Donors Modal */}
      <div className="modal" id="donors" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Donors List</h5>
              {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body">
              <div className="donor-list">
                <div className="donor-box">
                  <div className="img-box">
                    <figure>
                      <img src={ourDonor1} className="img-fluid" alt="" />
                    </figure>
                  </div>
                  <div className="content">
                    <div className="header">
                      <div className="name">
                        <h4>John Doe</h4>
                      </div>
                      <div className="amount">
                        <p>
                          <span className="property">Donate Amount</span>
                          <span className="value">$ 20.00</span>
                        </p>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt
                    </p>
                  </div>
                </div>
                <div className="donor-box">
                  <div className="img-box">
                    <figure>
                      <img src={ourDonor2} className="img-fluid" alt="" />
                    </figure>
                  </div>
                  <div className="content">
                    <div className="header">
                      <div className="name">
                        <h4>John Doe</h4>
                      </div>
                      <div className="amount">
                        <p>
                          <span className="property">Donate Amount</span>
                          <span className="value">$ 20.00</span>
                        </p>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt
                    </p>
                  </div>
                </div>
                <div className="donor-box">
                  <div className="img-box">
                    <figure>
                      <img src={ourDonor3} className="img-fluid" alt="" />
                    </figure>
                  </div>
                  <div className="content">
                    <div className="header">
                      <div className="name">
                        <h4>John Doe</h4>
                      </div>
                      <div className="amount">
                        <p>
                          <span className="property">Donate Amount</span>
                          <span className="value">$ 20.00</span>
                        </p>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt
                    </p>
                  </div>
                </div>
                <div className="donor-box">
                  <div className="img-box">
                    <figure>
                      <img src={ourDonor3} className="img-fluid" alt="" />
                    </figure>
                  </div>
                  <div className="content">
                    <div className="header">
                      <div className="name">
                        <h4>John Doe</h4>
                      </div>
                      <div className="amount">
                        <p>
                          <span className="property">Donate Amount</span>
                          <span className="value">$ 20.00</span>
                        </p>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt
                    </p>
                  </div>
                </div>
                <div className="donor-box">
                  <div className="img-box">
                    <figure>
                      <img src={ourDonor4} className="img-fluid" alt="" />
                    </figure>
                  </div>
                  <div className="content">
                    <div className="header">
                      <div className="name">
                        <h4>John Doe</h4>
                      </div>
                      <div className="amount">
                        <p>
                          <span className="property">Donate Amount</span>
                          <span className="value">$ 20.00</span>
                        </p>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt
                    </p>
                  </div>
                </div>
                <div className="donor-box">
                  <div className="img-box">
                    <figure>
                      <img src={ourDonor3} className="img-fluid" alt="" />
                    </figure>
                  </div>
                  <div className="content">
                    <div className="header">
                      <div className="name">
                        <h4>John Doe</h4>
                      </div>
                      <div className="amount">
                        <p>
                          <span className="property">Donate Amount</span>
                          <span className="value">$ 20.00</span>
                        </p>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt
                    </p>
                  </div>
                </div>
                <div className="donor-box">
                  <div className="img-box">
                    <figure>
                      <img src={ourDonor4} className="img-fluid" alt="" />
                    </figure>
                  </div>
                  <div className="content">
                    <div className="header">
                      <div className="name">
                        <h4>John Doe</h4>
                      </div>
                      <div className="amount">
                        <p>
                          <span className="property">Donate Amount</span>
                          <span className="value">$ 20.00</span>
                        </p>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Donors Modal */}
      <Footer />
    </>
  );
};

export default Campaign;
