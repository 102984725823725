import React, { useState } from "react";
import "../assets/css/product-detail.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {
  ArrowImg,
  BirdDetailImg,
  BirdImg2,
  BlogAfter,
  Cart1,
  Heart1,
  ProAfter,
  Showpiece,
  User1,
  User2,
  User3,
  User4,
} from "../constant";
import Slider from "react-slick";
const BirdDetail = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  return (
    <>
      <Header />
      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Kigi Bird Detail Page</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Product Detail Sec Start Here */}
      <section className="product-detail">
        <div className="before">
          <figure>
            <img src={BlogAfter} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="product-images">
                <div className="single-image">
                  <Slider
                    asNavFor={nav2}
                    arrows={false}
                    ref={(slider1) => setNav1(slider1)}
                  >
                    <figure>
                      <img src={BirdDetailImg} className="img-fluid" />
                    </figure>
                    <figure>
                      <img src={BirdDetailImg} className="img-fluid" />
                    </figure>
                    <figure>
                      <img src={BirdDetailImg} className="img-fluid" />
                    </figure>
                    <figure>
                      <img src={BirdDetailImg} className="img-fluid" />
                    </figure>
                  </Slider>
                </div>
                <div className="slider-images">
                  <Slider
                    asNavFor={nav1}
                    ref={(slider2) => setNav2(slider2)}
                    slidesToShow={3}
                    arrows={false}
                    swipeToSlide={true}
                    focusOnSelect={true}
                  >
                    <figure>
                      <img src={BirdImg2} className="img-fluid" />
                    </figure>
                    <figure>
                      <img src={BirdImg2} className="img-fluid" />
                    </figure>
                    <figure>
                      <img src={BirdImg2} className="img-fluid" />
                    </figure>
                    <figure>
                      <img src={BirdImg2} className="img-fluid" />
                    </figure>
                  </Slider>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="details">
                <div className="header">
                  <div className="heading">
                    <h4>Kigi Birds</h4>
                    <h3>Archimedes</h3>
                  </div>
                  {/* <div class="price-tag">
                            <h5>Unit Price : <span class="value">$50.50</span></h5>
                        </div> */}
                </div>
                <div className="description">
                  <h4>Discription</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla
                  </p>
                </div>
                {/* <div class="donation">
                        <div class="title">
                            <h4>Donation</h4>
                        </div>
                        <div class="input-group">
                            <div class="form-group br-right">
                                <label for="Amount">Amount to donate: </label>
                                <select class="form-control" id="Amount">
                                    <option>$5</option>
                                    <option>$12</option>
                                    <option>$20</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="Campaign">Select Campaign : </label>
                                <select class="form-control" id="Campaign">
                                    <option>Save Nature</option>
                                    <option>Save Nature1</option>
                                    <option>Save Nature2</option>
                                </select>
                            </div>
                        </div>
                    </div> */}
                <div className="button-group">
                  <button
                    onclick="window.location.href='donate'"
                    className="btn"
                  >
                    DONATE NOW <img src={ArrowImg} className="img-fluid" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={ProAfter} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* Product Detail Sec End Here */}
      <Footer />
    </>
  );
};

export default BirdDetail;
