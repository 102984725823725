import React from "react";
import "../assets/css/blog-detail.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { blogDetail, SmallAtferImg, SmallBeforeImg } from "../constant";

const BlogDetails = () => {
  return (
    <>
      <Header />

      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Blog Detail</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Blog Detail Sec Start Here */}
      <section className="blog-detail">
        <div className="before">
          <figure>
            <img src={SmallAtferImg} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="img-box">
                <figure>
                  <img src={blogDetail} className="img-fluid" />
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-wrapper">
                <h4>
                  Ornare massa eget egestas purus viverra accumsan. Egestas
                  fringilla phasellus faucibus scelerisque eleifend.
                </h4>
                <p>
                  Rhoncus mattis rhoncus urna neque viverra justo nec ultrices.
                  Ornare massa eget egestas purus viverra accumsan. Egestas
                  fringilla phasellus faucibus scelerisque eleifend donec
                  pretium vulputate. Vehicula ipsum a arcu cursus vitae congue
                  mauris rhoncus. Sem nulla pharetra diam sit amet nisl. Ipsum
                  dolor sit amet consectetur adipiscing elit pellentesque
                  habitant. Ultrices mi tempus imperdiet nulla malesuada
                  pellentesque elit. Imperdiet dui accumsan sit amet nulla
                  facilisi morbi tempus iaculis. Volutpat consequat mauris nunc
                  congue nisi vitae.
                  <br />
                  <br />
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Nunc scelerisque viverra mauris in aliquam sem fringilla ut
                  morbi. Ipsum dolor sit amet consectetur. Ac turpis egestas
                  integer eget aliquet nibh praesent tristique. At tempor
                  commodo ullamcorper a lacus vestibulum sed arcu. Faucibus
                  turpis in eu mi bibendum neque egestas congue quisque. Ut sem
                  viverra aliquet eget sit amet tellus cras adipiscing. Pretium
                  aenean pharetra magna ac placerat vestibulum. Nec ullamcorper
                  sit amet risus nullam eget felis eget nunc. Id aliquet risus
                  feugiat in ante metus. Vel orci porta non pulvinar neque
                  laoreet suspendisse interdum.
                </p>
              </div>
            </div>
            <br />
            <br />
            <div className="col-md-12">
              <div className="more-detail">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Nunc scelerisque viverra mauris in aliquam sem fringilla ut
                  morbi. Ipsum dolor sit amet consectetur. Ac turpis egestas
                  integer eget aliquet nibh praesent tristique. At tempor
                  commodo ullamcorper a lacus vestibulum sed arcu. Faucibus
                  turpis in eu mi bibendum neque egestas congue quisque. Ut sem
                  viverra aliquet eget sit amet tellus cras adipiscing. Pretium
                  aenean pharetra magna ac placerat vestibulum. Nec ullamcorper
                  sit amet risus nullam eget felis eget nunc. Id aliquet risus
                  feugiat in ante metus. Vel orci porta non pulvinar neque
                  laoreet suspendisse interdum.
                  <br />
                  <br />
                  Rhoncus mattis rhoncus urna neque viverra justo nec ultrices.
                  Ornare massa eget egestas purus viverra accumsan. Egestas
                  fringilla phasellus faucibus scelerisque eleifend donec
                  pretium vulputate. Vehicula ipsum a arcu cursus vitae congue
                  mauris rhoncus. Sem nulla pharetra diam sit amet nisl. Ipsum
                  dolor sit amet consectetur adipiscing elit pellentesque
                  habitant. Ultrices mi tempus imperdiet nulla malesuada
                  pellentesque elit. Imperdiet dui accumsan sit amet nulla
                  facilisi morbi tempus iaculis. Volutpat consequat mauris nunc
                  congue nisi vitae. Nisi quis eleifend quam adipiscing vitae.
                  Sit amet consectetur adipiscing elit duis tristique
                  sollicitudin nibh. Senectus et netus et malesuada fames ac
                  turpis egestas. Scelerisque eu ultrices vitae auctor eu augue
                  ut lectus. Viverra accumsan in nisl nisi. Ante metus dictum at
                  tempor commodo ullamcorper a lacus. Sit amet nisl purus in
                  mollis nunc sed id. Urna nunc id cursus metus aliquam eleifend
                  mi in. Ut tortor pretium viverra suspendisse. Pharetra
                  pharetra massa massa ultricies mi quis hendrerit dolor magna.
                  <br />
                  <br />
                  Enim nunc faucibus a pellentesque sit amet. Blandit volutpat
                  maecenas volutpat blandit aliquam etiam erat velit
                  scelerisque. Feugiat nisl pretium fusce id velit. Leo in vitae
                  turpis massa sed. Mi tempus imperdiet nulla malesuada
                  pellentesque elit eget gravida cum. Odio ut enim blandit
                  volutpat. Cras semper auctor neque vitae tempus quam
                  pellentesque nec nam. Malesuada fames ac turpis egestas
                  integer. Nec ultrices dui sapien eget mi proin sed libero.
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames.
                  <br />
                  <br />
                  Eu volutpat odio facilisis mauris sit. Dolor purus non enim
                  praesent elementum. Porttitor eget dolor morbi non. Consequat
                  semper viverra nam libero justo laoreet sit amet. Metus
                  aliquam eleifend mi in nulla posuere sollicitudin. Auctor eu
                  augue ut lectus arcu bibendum at varius vel. Id aliquet risus
                  feugiat in ante metus dictum. Aliquet eget sit amet tellus
                  cras adipiscing enim eu. Semper auctor neque vitae tempus quam
                  pellentesque nec. Justo laoreet sit amet cursus sit amet
                  dictum. Tellus integer feugiat scelerisque varius morbi enim
                  nunc faucibus a. Mauris cursus mattis molestie a iaculis. At
                  lectus urna duis convallis. Blandit cursus risus at ultrices
                  mi tempus imperdiet nulla malesuada.
                  <br />
                  <br />
                  Turpis egestas maecenas pharetra convallis posuere morbi.
                  Aenean pharetra magna ac placerat vestibulum lectus. Vitae
                  congue eu consequat ac felis donec et odio. Convallis a cras
                  semper auctor neque vitae tempus quam. Diam sit amet nisl
                  suscipit adipiscing bibendum est ultricies integer. Ac
                  placerat vestibulum lectus mauris ultrices eros in cursus.
                  Sapien nec sagittis aliquam malesuada bibendum arcu vitae
                  elementum curabitur. Vel elit scelerisque mauris pellentesque
                  pulvinar. Adipiscing at in tellus integer. Diam phasellus
                  vestibulum lorem sed risus ultricies. Imperdiet proin
                  fermentum leo vel orci porta non pulvinar neque. Turpis in eu
                  mi bibendum neque. Feugiat scelerisque varius morbi enim nunc
                  faucibus.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={SmallBeforeImg} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* Blog Detail Sec End Here */}

      <Footer />
    </>
  );
};

export default BlogDetails;
