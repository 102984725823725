import React, { useState } from "react";
import "../assets/css/product-detail.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { ArrowImg, BlogAfter, Cart, Heart1, PlantMaxImg, ProAfter, User1, User2, User3, User4, User5 } from "../constant";
import Slider from "react-slick";
const ProductDetail2 = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  return (
    <>
      <Header />
      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Premium Kigi Soil</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Product Detail Sec Start Here */}
      <section className="product-detail">
        <div className="before">
          <figure>
            <img src={BlogAfter} className="img-fluid" alt="" />
          </figure>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="product-images">
                <div className="single-image">
                  <Slider
                    asNavFor={nav2}
                    arrows={false}
                    ref={(slider1) => setNav1(slider1)}
                  >
                    <figure>
                      <img src={PlantMaxImg} className="img-fluid" alt="" />
                    </figure>
                    <figure>
                      <img src={PlantMaxImg} className="img-fluid" alt="" />
                    </figure>
                    <figure>
                      <img src={PlantMaxImg} className="img-fluid" alt="" />
                    </figure>
                    <figure>
                      <img src={PlantMaxImg} className="img-fluid" alt="" />
                    </figure>
                  </Slider>
                </div>
                <div className="slider-images">
                  <Slider
                    asNavFor={nav1}
                    ref={(slider2) => setNav2(slider2)}
                    slidesToShow={3}
                    arrows={false}
                    swipeToSlide={true}
                    focusOnSelect={true}
                  >
                    <figure>
                      <img src={PlantMaxImg} className="img-fluid" alt="" />
                    </figure>
                    <figure>
                      <img src={PlantMaxImg} className="img-fluid" alt="" />
                    </figure>
                    <figure>
                      <img src={PlantMaxImg} className="img-fluid" alt="" />
                    </figure>
                    <figure>
                      <img src={PlantMaxImg} className="img-fluid" alt="" />
                    </figure>
                  </Slider>
                </div>
              </div>
              <div className="reviews_sec">
                <div className="heading">
                  <h3>Reviews</h3>
                </div>
                <div className="reviewDv">
                  <div className="questionDv reviews">
                    <div className="user-img">
                      <figure>
                        <img src={User1} className="img-fluid" alt="" />
                      </figure>
                    </div>
                    <div className="detailDv">
                      <div className="header">
                        <h5 className="name">Joeby Ragpa </h5>
                        <p>- 12 April, 2014 at 16:50</p>
                      </div>
                      <p>
                        We possess within us two minds. So far I have written
                        only of the conscious mind. I would now like to
                        introduce you to your second mind, the hidden and
                        mysterious subconscious. Our subconscious mind contains
                        such power and complexity that it literally staggers the
                        imagination.
                      </p>
                      <div className="bottom-bar">
                        <div className="rating">
                          <ul className="stars">
                            <li>
                              <i className="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              <i className="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              <i className="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              <i className="fa fa-star-o" aria-hidden="true" />
                            </li>
                            <li>
                              <i className="fa fa-star-o" aria-hidden="true" />
                            </li>
                          </ul>
                          <p className="count">3.9</p>
                        </div>
                        <div className="rplyDv">
                          <a href="#">Reply</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="answersDv">
                    <div className="vertical-line" />
                    <div className="answer reviews">
                      <div className="user-img">
                        <figure>
                          <img
                            src={User2}
                            className="img-fluid"
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="detailDv">
                        <div className="header">
                          <h5 className="name">Joeby Ragpa </h5>
                          <p>- 12 April, 2014 at 16:50</p>
                        </div>
                        <p>
                          We possess within us two minds. So far I have written
                          only of the conscious mind. I would now like to
                          introduce you to your second mind the hidden and
                          mysterious subconscious.
                        </p>
                      </div>
                    </div>
                    <div className="answer reviews">
                      <div className="user-img">
                        <figure>
                          <img
                            src={User3}
                            className="img-fluid"
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="detailDv">
                        <div className="header">
                          <h5 className="name">Joeby Ragpa </h5>
                          <p>- 12 April, 2014 at 16:50</p>
                        </div>
                        <p>
                          We possess within us two minds. So far I have written
                          only of the conscious mind. I would now like to
                          introduce you to your
                        </p>
                      </div>
                    </div>
                    <div className="answer reviews">
                      <div className="user-img">
                        <figure>
                          <img
                            src={User4}
                            className="img-fluid"
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="detailDv">
                        <div className="header">
                          <h5 className="name">Joeby Ragpa </h5>
                          <p>- 12 April, 2014 at 16:50</p>
                        </div>
                        <p>
                          We possess within us two minds. So far I have written
                          only of the conscious mind. I would now like to
                          introduce you to your second mind,
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="reviewDv">
                  <div className="questionDv reviews">
                    <div className="user-img">
                      <figure>
                        <img src={User1} className="img-fluid" alt="" />
                      </figure>
                    </div>
                    <div className="detailDv">
                      <div className="header">
                        <h5 className="name">Joeby Ragpa </h5>
                        <p>- 12 April, 2014 at 16:50</p>
                      </div>
                      <p>
                        We possess within us two minds. So far I have written
                        only of the conscious mind. I would now like to
                        introduce you to your second mind, the hidden and
                        mysterious subconscious. Our subconscious mind contains
                        such power and complexity that it literally staggers the
                        imagination.
                      </p>
                      <div className="bottom-bar">
                        <div className="rating">
                          <ul className="stars">
                            <li>
                              <i className="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              <i className="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              <i className="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              <i className="fa fa-star-o" aria-hidden="true" />
                            </li>
                            <li>
                              <i className="fa fa-star-o" aria-hidden="true" />
                            </li>
                          </ul>
                          <p className="count">3.9</p>
                        </div>
                        <div className="rplyDv">
                          <a href="#">Reply</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="post-review">
                <div className="header">
                  <div className="left-side">
                    <figure>
                      <img src={User5} alt="" className="img-fluid" />
                    </figure>
                    <div className="name">
                      <h4>Bruce Wayne</h4>
                      <p className="tagline">
                        Your review will be Posted on Web
                      </p>
                    </div>
                  </div>
                  <div className="right-side">
                    <div className="rate">
                      <input
                        type="radio"
                        hidden
                        id="star5"
                        name="rate"
                        defaultValue={5}
                      />
                      <label htmlFor="star5" title="text">
                        5 stars
                      </label>
                      <input
                        type="radio"
                        hidden
                        id="star4"
                        name="rate"
                        defaultValue={4}
                      />
                      <label htmlFor="star4" title="text">
                        4 stars
                      </label>
                      <input
                        type="radio"
                        hidden
                        id="star3"
                        name="rate"
                        defaultValue={3}
                      />
                      <label htmlFor="star3" title="text">
                        3 stars
                      </label>
                      <input
                        type="radio"
                        hidden
                        id="star2"
                        name="rate"
                        defaultValue={2}
                      />
                      <label htmlFor="star2" title="text">
                        2 stars
                      </label>
                      <input
                        type="radio"
                        hidden
                        id="star1"
                        name="rate"
                        defaultValue={1}
                      />
                      <label htmlFor="star1" title="text">
                        1 star
                      </label>
                    </div>
                  </div>
                </div>
                <form action>
                  <textarea
                    placeholder="Write Your Review Here"
                    className="form-control"
                    defaultValue={""}
                  />
                  <button className="btn">
                    Submit review{" "}
                    <img src={ArrowImg} className="img-fluid" alt="" />
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="details">
                <div className="header">
                  <div className="heading">
                    <h4>Planting Mixes</h4>
                    <h3>Premium Kigi Soil</h3>
                  </div>
                  {/* <div class="price-tag">
                            <h5>Unit Price : <span class="value">$50.50</span></h5>
                        </div> */}
                </div>
                <div className="description">
                  <h4>Discription</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla
                  </p>
                </div>
                {/* <div class="additional-info">
                        <h4>Additional Information </h4>
                    </div> */}
                <div className="variations mt-4">
                  <div className="form-group">
                    <label htmlFor="SelectAdds">Choose Your Pack : </label>
                    <div className="input-group">
                      <label className="add_container">
                        <input
                          type="radio"
                          hidden
                          defaultChecked="checked"
                          name="radio"
                        />
                        <span className="checkmark">
                          <h5 className="count">3</h5>
                          <h5 className="price">$5</h5>
                        </span>
                      </label>
                      <label className="add_container">
                        <input type="radio" hidden name="radio" />
                        <span className="checkmark">
                          <h5 className="count">6</h5>
                          <h5 className="price">$15</h5>
                        </span>
                      </label>
                      <label className="add_container">
                        <input type="radio" hidden name="radio" />
                        <span className="checkmark">
                          <h5 className="count">9</h5>
                          <h5 className="price">$45</h5>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="donation">
                  <div className="title">
                    <h4>Donation</h4>
                  </div>
                  <div className="input-group">
                    <div className="form-group br-right">
                      <label htmlFor="Amount">Amount to donate: </label>
                      <select className="form-control" id="Amount">
                        <option>$5</option>
                        <option>$12</option>
                        <option>$20</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="Campaign">Select Campaign : </label>
                      <select className="form-control" id="Campaign">
                        <option>Save Nature</option>
                        <option>Save Nature1</option>
                        <option>Save Nature2</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="button-group">
                  <button
                    onclick="window.location.href='cart'"
                    className="btn"
                  >
                    Add to cart{" "}
                    <img src={Cart} className="img-fluid" alt="" />
                  </button>
                  <button
                    onclick="window.location.href='wishlist'"
                    className="btn"
                  >
                    Add to wishlist{" "}
                    <img src={Heart1} className="img-fluid" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={ProAfter} className="img-fluid" alt="" />
          </figure>
        </div>
      </section>
      {/* Product Detail Sec End Here */}
      <Footer />
    </>
  );
};

export default ProductDetail2;
