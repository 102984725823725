import React from "react";
import "../assets/css/blogs.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { ArrowImg, blogs1, blogs2, blogs3, blogs4, SmallAtferImg, SmallBeforeImg } from "../constant";

const Blog = () => {
  return (
    <>
      <Header />

      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Blogs</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Blogs Sec Start Here */}
      <section className="blogs">
        <div className="before">
          <figure>
            <img src={SmallAtferImg} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="all-blogs">
            <div className="row blog-box mb-4">
              <div className="col-lg-4">
                <div className="img-box">
                  <figure>
                    <img src={blogs1} className="img-fluid" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-8">
                <h3 className="title">
                  Ornare massa eget egestas purus viverra accumsan. Egestas
                  fringilla phasellus faucibus scelerisque eleifend.
                </h3>
                <p>
                  Rhoncus mattis rhoncus urna neque viverra justo nec ultrices.
                  Ornare massa eget egestas purus viverra accumsan. Egestas
                  fringilla phasellus faucibus scelerisque eleifend donec
                  pretium vulputate. Vehicula ipsum a arcu cursus vitae congue
                  mauris rhoncus. Sem nulla pharetra diam sit amet nisl. Ipsum
                  dolor sit amet consectetur adipiscing elit pellentesque
                  habitant. Ultrices mi tempus imperdiet nulla malesuada
                  pellentesque elit. Imperdiet dui accumsan sit amet nulla
                  facilisi morbi tempus iaculis. Volutpat consequat mauris nunc
                  congue nisi vitae.
                </p>
                <div className="button-group">
                  <a href="blog-detail" className="btn">
                    Read More
                    <img src={ArrowImg} className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
            <div className="row blog-box mb-4">
              <div className="col-lg-4">
                <div className="img-box">
                  <figure>
                    <img src={blogs2} className="img-fluid" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-8">
                <h3 className="title">
                  Ornare massa eget egestas purus viverra accumsan. Egestas
                  fringilla phasellus faucibus scelerisque eleifend.
                </h3>
                <p>
                  Rhoncus mattis rhoncus urna neque viverra justo nec ultrices.
                  Ornare massa eget egestas purus viverra accumsan. Egestas
                  fringilla phasellus faucibus scelerisque eleifend donec
                  pretium vulputate. Vehicula ipsum a arcu cursus vitae congue
                  mauris rhoncus. Sem nulla pharetra diam sit amet nisl. Ipsum
                  dolor sit amet consectetur adipiscing elit pellentesque
                  habitant. Ultrices mi tempus imperdiet nulla malesuada
                  pellentesque elit. Imperdiet dui accumsan sit amet nulla
                  facilisi morbi tempus iaculis. Volutpat consequat mauris nunc
                  congue nisi vitae.
                </p>
                <div className="button-group">
                  <a href="blog-detail" className="btn">
                    Read More
                    <img src={ArrowImg} className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
            <div className="row blog-box mb-4">
              <div className="col-lg-4">
                <div className="img-box">
                  <figure>
                    <img src={blogs3} className="img-fluid" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-8">
                <h3 className="title">
                  Ornare massa eget egestas purus viverra accumsan. Egestas
                  fringilla phasellus faucibus scelerisque eleifend.
                </h3>
                <p>
                  Rhoncus mattis rhoncus urna neque viverra justo nec ultrices.
                  Ornare massa eget egestas purus viverra accumsan. Egestas
                  fringilla phasellus faucibus scelerisque eleifend donec
                  pretium vulputate. Vehicula ipsum a arcu cursus vitae congue
                  mauris rhoncus. Sem nulla pharetra diam sit amet nisl. Ipsum
                  dolor sit amet consectetur adipiscing elit pellentesque
                  habitant. Ultrices mi tempus imperdiet nulla malesuada
                  pellentesque elit. Imperdiet dui accumsan sit amet nulla
                  facilisi morbi tempus iaculis. Volutpat consequat mauris nunc
                  congue nisi vitae.
                </p>
                <div className="button-group">
                  <a href="blog-detail" className="btn">
                    Read More
                    <img src={ArrowImg} className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
            <div className="row blog-box mb-4">
              <div className="col-lg-4">
                <div className="img-box">
                  <figure>
                    <img src={blogs4} className="img-fluid" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-8">
                <h3 className="title">
                  Ornare massa eget egestas purus viverra accumsan. Egestas
                  fringilla phasellus faucibus scelerisque eleifend.
                </h3>
                <p>
                  Rhoncus mattis rhoncus urna neque viverra justo nec ultrices.
                  Ornare massa eget egestas purus viverra accumsan. Egestas
                  fringilla phasellus faucibus scelerisque eleifend donec
                  pretium vulputate. Vehicula ipsum a arcu cursus vitae congue
                  mauris rhoncus. Sem nulla pharetra diam sit amet nisl. Ipsum
                  dolor sit amet consectetur adipiscing elit pellentesque
                  habitant. Ultrices mi tempus imperdiet nulla malesuada
                  pellentesque elit. Imperdiet dui accumsan sit amet nulla
                  facilisi morbi tempus iaculis. Volutpat consequat mauris nunc
                  congue nisi vitae.
                </p>
                <div className="button-group">
                  <a href="blog-detail" className="btn">
                    Read More
                    <img src={ArrowImg} className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={SmallBeforeImg} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* Blogs Sec End Here */}
      <Footer />
    </>
  );
};

export default Blog;
