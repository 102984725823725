import React from "react";
import "../assets/css/gift-certificate.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {} from "../constant";

const GiftCertificate = () => {
  return (
    <>
      <Header />

      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Gift Certificates</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Gift Sec Start Here */}
      <section className="gift_sec">
        <div className="container">
          <div className="heading">
            <h2>Send Gift Amounts to Your friends and family</h2>
            <p>
              : Gift Certificate code send to Recipient via email, and the code
              is only valid for 365 days. The recipient can redeem the amount by
              applying the code at checkout. Also, the recipient can check the
              balance amount by entering the certificate code.
            </p>
          </div>
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-8">
              <form action="thank-you">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Your Name</label>
                      <input
                        type="text"
                        placeholder="Write Your Name"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Your Email</label>
                      <input
                        type="email"
                        placeholder="Write Your Email"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Recipient’s Name</label>
                      <input
                        type="text"
                        placeholder="Write Recipient’s Name"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Recipient’s Email</label>
                      <input
                        type="email"
                        placeholder="Write Recipient’s Email"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Enter Gift Amount</label>
                      <input
                        type="text"
                        placeholder="Write gift Amount"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Gift Certificate Theme</label>
                      <select className="form-control">
                        <option>Select Theme</option>
                        <option>Theme1</option>
                        <option>Theme2</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Your Message</label>
                      <input
                        type="text"
                        placeholder="Write your Message Here"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="radio"
                        id="radio-check"
                        className="form-check-input"
                      />
                      <label htmlFor="radio-check">
                        I understand that Gift Certificates expire after 365
                        days
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        type="radio"
                        id="radio-check2"
                        className="form-check-input"
                      />
                      <label htmlFor="radio-check2">
                        I agree that Gift Certificates are non-refundable
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="button-group">
                      <button className="btn">Apply</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-2" />
          </div>
        </div>
      </section>
      {/* Gift Sec End Here */}
      <Footer />
    </>
  );
};

export default GiftCertificate;
