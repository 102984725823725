import React from "react";
import ReactPlayer from 'react-player'
import {
  ArrowImg,
  ArrowImg1,
  BannerVideo,
  Blog1,
  Blog2,
  Blog3,
  Blog4,
  Blog5,
  BlogAfter,
  Btn,
  CatBefore,
  Cate1,
  Cate2,
  Cate3,
  Icon1,
  Icon2,
  Icon3,
  Insta1,
  Insta2,
  Insta3,
  Insta4,
  Insta5,
  ProAfter,
  ProBefore,
  Product1,
  Product2,
  Product3,
  Product4,
  Product5,
  StepBefore,
  Work1,
  Work2,
  Work3,
} from "../constant";
import "../assets/css/style.css";
import Footer from "../component/Footer";
import Header from "../component/Header";
import Slider from "react-slick";

const Home = () => {
  const ProductSlider = {
    arrows: false,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const BlogSlider = {
    arrows: false,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const InstaFeed = {
    arrows: false,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 4,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  
  function playPause() {
    let myVideo = document.getElementById("myvid");
    if (myVideo.paused) {
      console.log("wokring if play works");
      myVideo.play();
    } else {
      console.log("wokring else play works");
      myVideo.pause();
    }
  }
  return (
    <>
      <Header />
      {/* Body Content Start Here */}
      {/* Banner Sec Start Here */}
      <section className="banner_sec">
        <div className="container">
          <div className="banner_content">
            <div className="video_box">
                
              <video width="100%" id="myvid">
                <source src={BannerVideo} type="video/mp4" />
              </video>
              <div className="toggle-btn" onClick={playPause} />
            </div>
            <div className="content_dv">
              <h1>We Deliver Great Plants</h1>
              <div className="button-group">
                <a href="shop" className="btn">
                  SHOP NOW <img src={ArrowImg} className="img-fluid" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Banner Sec End Here */}
      {/* Quality Sec Start Here */}
      <section className="quality_sec">
        <div className="container">
          <div className="boxes">
            <div className="row">
              <div className="col-md-4">
                <div className="icon_with_text">
                  <div className="iconDv">
                    <figure>
                      <img src={Icon1} className="img-fluid" />
                    </figure>
                    <h4>Friendly Service</h4>
                  </div>
                  <div className="txt">
                    <p>
                      Duis convallis convallis tellus id interdum velit laoreet
                      id donec vitae nunc
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="icon_with_text">
                  <div className="iconDv">
                    <figure>
                      <img src={Icon2} className="img-fluid" />
                    </figure>
                    <h4>Free Delivery</h4>
                  </div>
                  <div className="txt">
                    <p>
                      Duis convallis convallis tellus id interdum velit laoreet
                      id donec vitae nunc
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="icon_with_text">
                  <div className="iconDv">
                    <figure>
                      <img src={Icon3} className="img-fluid" />
                    </figure>
                    <h4>Safe Payment</h4>
                  </div>
                  <div className="txt">
                    <p>
                      Duis convallis convallis tellus id interdum velit laoreet
                      id donec vitae nunc
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Quality Sec End Here */}
      {/* Browse Product Sec Start Here */}
      <section className="prouct-sec">
        <div className="left">
          <figure>
            <img src={ProBefore} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="heading">
            <h2>
              We Have Over 1000 Varieties For Optimal Biodiversity In Your
              Landscape
            </h2>
          </div>
          <div className="discover">
            <div className="heading_wrapper">
              <h2>Browse Our Products</h2>
              <p>
                Duis convallis convallis tellus id interdum velit laoreet id
                donec. Vitae nunc sed velit dignissim. Facilisi cras fermentum
                odio eu.
              </p>
            </div>
            <div className="view-all">
              <a href="shop" className="lnk">
                View more <img src={Btn} className="img-fluid" />
              </a>
            </div>
          </div>
        </div>
        <div className="product-slider">
          <Slider {...ProductSlider}>
            <div className="product-box">
              <a href="product-detail">
                <div className="pro-img">
                  <figure>
                    <img src={Product1} alt="" className="img-fluid" />
                  </figure>
                </div>
                <div className="pro-detail">
                  <div className="name">
                    <h4>Whisper Coneflower</h4>
                  </div>
                  <div className="price">
                    <h4>$250.00</h4>
                  </div>
                </div>
              </a>
            </div>
            <div className="product-box">
              <a href="product-detail">
                <div className="pro-img">
                  <figure>
                    <img src={Product2} alt="" className="img-fluid" />
                  </figure>
                </div>
                <div className="pro-detail">
                  <div className="name">
                    <h4>Whisper Coneflower</h4>
                  </div>
                  <div className="price">
                    <h4>$250.00</h4>
                  </div>
                </div>
              </a>
            </div>
            <div className="product-box">
              <a href="product-detail">
                <div className="pro-img">
                  <figure>
                    <img src={Product3} alt="" className="img-fluid" />
                  </figure>
                </div>
                <div className="pro-detail">
                  <div className="name">
                    <h4>Whisper Coneflower</h4>
                  </div>
                  <div className="price">
                    <h4>$250.00</h4>
                  </div>
                </div>
              </a>
            </div>
            <div className="product-box">
              <a href="product-detail">
                <div className="pro-img">
                  <figure>
                    <img src={Product4} alt="" className="img-fluid" />
                  </figure>
                </div>
                <div className="pro-detail">
                  <div className="name">
                    <h4>Whisper Coneflower</h4>
                  </div>
                  <div className="price">
                    <h4>$250.00</h4>
                  </div>
                </div>
              </a>
            </div>
            <div className="product-box">
              <a href="product-detail">
                <div className="pro-img">
                  <figure>
                    <img src={Product5} alt="" className="img-fluid" />
                  </figure>
                </div>
                <div className="pro-detail">
                  <div className="name">
                    <h4>Whisper Coneflower</h4>
                  </div>
                  <div className="price">
                    <h4>$250.00</h4>
                  </div>
                </div>
              </a>
            </div>
          </Slider>
        </div>
        <div className="right">
          <figure>
            <img src={ProAfter} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* Browse Product Sec End Here */}
      {/* Categories Sec Start Here */}
      <section className="categories_sec">
        <div className="before">
          <figure>
            <img src={CatBefore} alt="" className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="discover">
            <div className="heading_wrapper">
              <h2>Categories</h2>
            </div>
            <div className="view-all">
              <a href="shop" className="lnk">
                Browse More <img src={Btn} className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7">
              <div className="categories_box frst_box">
                <div className="content">
                  <h2 className="title">Maples</h2>
                  <p>
                    Duis convallis convallis tellus id interdum velit laoreet id
                    donec vitae nunc
                  </p>
                  <div className="button-group">
                    <a href="shop" className="btn">
                      SHOP NOW <img src={ArrowImg} className="img-fluid" />
                    </a>
                  </div>
                </div>
                <div className="img_box frstImg">
                  <figure>
                    <img src={Cate1} className="img-fluid" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="categories_box scnd_box">
                <div className="content">
                  <h2 className="title">Conifers</h2>
                  <p>
                    Duis convallis convallis tellus id interdum velit laoreet id
                    donec vitae nunc
                  </p>
                  <div className="button-group">
                    <a href="shop" className="btn">
                      SHOP NOW <img src={ArrowImg} className="img-fluid" />
                    </a>
                  </div>
                </div>
                <div className="img_box scndImg">
                  <figure>
                    <img src={Cate2} className="img-fluid" />
                  </figure>
                </div>
              </div>
              <div className="spacer" />
              <div className="categories_box thrd_box">
                <div className="content">
                  <h2 className="title">Other Plants</h2>
                  <p>
                    Duis convallis convallis tellus id interdum velit laoreet id
                    donec vitae nunc
                  </p>
                  <div className="button-group">
                    <a href="shop" className="btn">
                      SHOP NOW <img src={ArrowImg} className="img-fluid" />
                    </a>
                  </div>
                </div>
                <div className="img_box thrdImg">
                  <figure>
                    <img src={Cate3} className="img-fluid" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Categories Sec End Here */}
      {/* How It Work Sec Start Here */}
      <section className="how_it_work">
        <div className="container">
          <div className="discover">
            <div className="heading_wrapper">
              <h2>How It Works</h2>
            </div>
            <div className="view-all">
              <a href="about" className="lnk">
                Read More <img src={Btn} className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="wor_box">
                <div className="img_box">
                  <figure>
                    <img src={Work1} className="img-fluid" />
                  </figure>
                </div>
                <div className="content">
                  <h4>Step 1</h4>
                  <h5>
                    <span>We grow them in</span>
                    <br /> Optimal Environment{" "}
                  </h5>
                </div>
                <div className="arrow">
                  <figure>
                    <img src={ArrowImg1} className="img-fluid" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="wor_box">
                <div className="img_box">
                  <figure>
                    <img src={Work2} className="img-fluid" />
                  </figure>
                </div>
                <div className="content">
                  <h4>Step 2</h4>
                  <h5>
                    <span>We Box them at the</span>
                    <br /> Right time
                  </h5>
                </div>
                <div className="arrow">
                  <figure>
                    <img src={ArrowImg1} className="img-fluid" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="wor_box">
                <div className="img_box">
                  <figure>
                    <img src={Work3} className="img-fluid" />
                  </figure>
                </div>
                <div className="content">
                  <h4>Step 3</h4>
                  <h5>
                    <span>We deliver to</span>
                    <br /> Your Doorstep
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={StepBefore} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* How It Work Sec End Here */}
      {/* Blog Sec Start Here */}
      <section className="blog_sec">
        <div className="blog-before">
          <figure>
            <img src={BlogAfter} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="discover">
            <div className="heading_wrapper">
              <h2>Our Blogs</h2>
            </div>
            <div className="view-all">
              <a href="blogs" className="lnk">
                View More <img src={Btn} className="img-fluid" />
              </a>
            </div>
          </div>
        </div>
        <div className="blog_slider">
          <Slider {...BlogSlider}>
            <div className="blog_box">
              <div className="blog-img">
                <figure>
                  <img src={Blog1} className="img-fluid" />
                </figure>
              </div>
              <div className="blog-content">
                <h4 className="date">11. August. 2021</h4>
                <h3 className="name">
                  Fermentum convallis convallis tellus id interdum velit laoreet
                  id donec...
                </h3>
                <p>
                  Duis convallis convallis tellus id interdum velit laoreet id
                  donec. Vitae nunc sed velit dignissim...
                </p>
                <div className="button-group">
                  <a href="blog-detail" className="lnk">
                    View More
                  </a>
                </div>
              </div>
            </div>
            <div className="blog_box">
              <div className="blog-img">
                <figure>
                  <img src={Blog2} className="img-fluid" />
                </figure>
              </div>
              <div className="blog-content">
                <h4 className="date">11. August. 2021</h4>
                <h3 className="name">
                  Fermentum convallis convallis tellus id interdum velit laoreet
                  id donec...
                </h3>
                <p>
                  Duis convallis convallis tellus id interdum velit laoreet id
                  donec. Vitae nunc sed velit dignissim...
                </p>
                <div className="button-group">
                  <a href="blog-detail" className="lnk">
                    View More
                  </a>
                </div>
              </div>
            </div>
            <div className="blog_box">
              <div className="blog-img">
                <figure>
                  <img src={Blog3} className="img-fluid" />
                </figure>
              </div>
              <div className="blog-content">
                <h4 className="date">11. August. 2021</h4>
                <h3 className="name">
                  Fermentum convallis convallis tellus id interdum velit laoreet
                  id donec...
                </h3>
                <p>
                  Duis convallis convallis tellus id interdum velit laoreet id
                  donec. Vitae nunc sed velit dignissim...
                </p>
                <div className="button-group">
                  <a href="blog-detail" className="lnk">
                    View More
                  </a>
                </div>
              </div>
            </div>
            <div className="blog_box">
              <div className="blog-img">
                <figure>
                  <img src={Blog4} className="img-fluid" />
                </figure>
              </div>
              <div className="blog-content">
                <h4 className="date">11. August. 2021</h4>
                <h3 className="name">
                  Fermentum convallis convallis tellus id interdum velit laoreet
                  id donec...
                </h3>
                <p>
                  Duis convallis convallis tellus id interdum velit laoreet id
                  donec. Vitae nunc sed velit dignissim...
                </p>
                <div className="button-group">
                  <a href="blog-detail" className="lnk">
                    View More
                  </a>
                </div>
              </div>
            </div>
            <div className="blog_box">
              <div className="blog-img">
                <figure>
                  <img src={Blog5} className="img-fluid" />
                </figure>
              </div>
              <div className="blog-content">
                <h4 className="date">11. August. 2021</h4>
                <h3 className="name">
                  Fermentum convallis convallis tellus id interdum velit laoreet
                  id donec...
                </h3>
                <p>
                  Duis convallis convallis tellus id interdum velit laoreet id
                  donec. Vitae nunc sed velit dignissim...
                </p>
                <div className="button-group">
                  <a href="blog-detail" className="lnk">
                    View More
                  </a>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
      {/* Blog Sec End Here */}
      {/* Social Sec Start Here */}
      <section className="social_sec">
        <div className="container">
          <div className="discover">
            <div className="heading_wrapper">
              <h2>Follow Us on social media</h2>
            </div>
            <ul className="social-icons">
              <li>
                <a href="#">
                  <i className="fa fa-youtube-play" aria-hidden="true" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-instagram" aria-hidden="true" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-facebook" aria-hidden="true" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-pinterest-p" aria-hidden="true" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-twitter" aria-hidden="true" />
                </a>
              </li>
              <li>
                <a href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    fill="currentColor"
                    className="bi bi-tiktok"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="all-feeds">
          <Slider {...InstaFeed}>
            <figure>
              <img src={Insta1} className="img-fluid" alt="123" />
            </figure>
            <figure>
              <img src={Insta2} className="img-fluid" alt="123" />
            </figure>
            <figure>
              <img src={Insta3} className="img-fluid" alt="123" />
            </figure>
            <figure>
              <img src={Insta4} className="img-fluid" alt="123" />
            </figure>
            <figure>
              <img src={Insta5} className="img-fluid" alt="123" />
            </figure>
          </Slider>
        </div>
      </section>
      {/* Social Sec End Here */}
      {/* Body Content End Here */}
      <Footer />
    </>
  );
};

export default Home;
