import React from "react";
import "../assets/css/gallery.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery8,
} from "../constant";

const Gallery = () => {
  return (
    <>
      <Header />

      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Image Gallery</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Gallery Sec Start Hwere */}
      <section className="gallery_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="geallery-img w-60 mb-3">
                <figure>
                  <img src={gallery1} className="img-fluid" alt="" />
                </figure>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="geallery-img w-40">
                    <figure>
                      <img src={gallery3} className="img-fluid" alt="" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="geallery-img w-40">
                    <figure>
                      <img src={gallery4} className="img-fluid" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="geallery-img w-40 mb-3">
                <figure>
                  <img src={gallery2} className="img-fluid" alt="" />
                </figure>
              </div>
              <div className="geallery-img w-60">
                <figure>
                  <img src={gallery5} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="geallery-img mt-3 w-30">
                <figure>
                  <img src={gallery6} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="geallery-img mt-3 w-30">
                <figure>
                  <img src={gallery8} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Gallery Sec End Hwere */}
      <Footer />
    </>
  );
};

export default Gallery;
