import React, { useState } from "react";
import "../assets/css/product-detail.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {
  ArrowImg,
  BlogAfter,
  Cart1,
  Heart1,
  MapImg,
  PlyBtn,
  ProAfter,
  Product2,
  Product3,
  Product4,
  ProductDetail1,
  ProductDetail1Images,
  User1,
  User2,
  User3,
  User4,
  User5,
  videoPoster,
} from "../constant";
import Slider from "react-slick";

const ProductDetail = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  return (
    <>
      <Header />
      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Whisper Coneflower</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Product Detail Sec Start Here */}
      <section className="product-detail">
        <div className="before">
          <figure>
            <img src={BlogAfter} className="img-fluid" alt="" />
          </figure>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="product-images">
                <div className="single-image">
                  <Slider asNavFor={nav2} arrows={false} ref={(slider1) => setNav1(slider1)}>
                    <figure>
                      <img src={ProductDetail1} className="img-fluid" alt="" />
                    </figure>
                    <figure>
                      <img src={ProductDetail1} className="img-fluid" alt="" />
                    </figure>
                    <figure>
                      <img src={ProductDetail1} className="img-fluid" alt="" />
                    </figure>
                    <figure>
                      <img src={ProductDetail1} className="img-fluid" alt="" />
                    </figure>
                  </Slider>
                </div>
                <div className="slider-images">
                  <Slider
                    asNavFor={nav1}
                    ref={(slider2) => setNav2(slider2)}
                    slidesToShow={3}
                    arrows={false}
                    swipeToSlide={true}
                    focusOnSelect={true}
                  >
                    <figure>
                      <img
                        src={ProductDetail1Images}
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                    <figure>
                      <img
                        src={ProductDetail1Images}
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                    <figure>
                      <img
                        src={ProductDetail1Images}
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                    <figure>
                      <img
                        src={ProductDetail1Images}
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                  </Slider>
                </div>
              </div>
              <div className="plant-video">
                <div className="heading">
                  <h2>Plant Video</h2>
                </div>
                <div className="video-wrapper">
                  <div className="video-container">
                    <video
                      id="Provideo"
                      preload="metadata"
                      poster={videoPoster}
                    >
                      <source src="img/banner-video.mp4" type="video/mp4" />
                    </video>
                    <div className="play-button-wrapper">
                      <div
                        title="Play video"
                        className="play-gif"
                        id="circle-play-b"
                      >
                        <img src={PlyBtn} className="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="reviews_sec">
                <div className="heading">
                  <h3>Reviews</h3>
                </div>
                <div className="reviewDv">
                  <div className="questionDv reviews">
                    <div className="user-img">
                      <figure>
                        <img src={User1} className="img-fluid" alt="" />
                      </figure>
                    </div>
                    <div className="detailDv">
                      <div className="header">
                        <h5 className="name">Joeby Ragpa </h5>
                        <p>- 12 April, 2014 at 16:50</p>
                      </div>
                      <p>
                        We possess within us two minds. So far I have written
                        only of the conscious mind. I would now like to
                        introduce you to your second mind, the hidden and
                        mysterious subconscious. Our subconscious mind contains
                        such power and complexity that it literally staggers the
                        imagination.
                      </p>
                      <div className="bottom-bar">
                        <div className="rating">
                          <ul className="stars">
                            <li>
                              <i className="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              <i className="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              <i className="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              <i className="fa fa-star-o" aria-hidden="true" />
                            </li>
                            <li>
                              <i className="fa fa-star-o" aria-hidden="true" />
                            </li>
                          </ul>
                          <p className="count">3.9</p>
                        </div>
                        <div className="rplyDv">
                          <a href="#">Reply</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="answersDv">
                    <div className="vertical-line" />
                    <div className="answer reviews">
                      <div className="user-img">
                        <figure>
                          <img src={User2} className="img-fluid" alt="" />
                        </figure>
                      </div>
                      <div className="detailDv">
                        <div className="header">
                          <h5 className="name">Joeby Ragpa </h5>
                          <p>- 12 April, 2014 at 16:50</p>
                        </div>
                        <p>
                          We possess within us two minds. So far I have written
                          only of the conscious mind. I would now like to
                          introduce you to your second mind the hidden and
                          mysterious subconscious.
                        </p>
                      </div>
                    </div>
                    <div className="answer reviews">
                      <div className="user-img">
                        <figure>
                          <img src={User3} className="img-fluid" alt="" />
                        </figure>
                      </div>
                      <div className="detailDv">
                        <div className="header">
                          <h5 className="name">Joeby Ragpa </h5>
                          <p>- 12 April, 2014 at 16:50</p>
                        </div>
                        <p>
                          We possess within us two minds. So far I have written
                          only of the conscious mind. I would now like to
                          introduce you to your
                        </p>
                      </div>
                    </div>
                    <div className="answer reviews">
                      <div className="user-img">
                        <figure>
                          <img src={User4} className="img-fluid" alt="" />
                        </figure>
                      </div>
                      <div className="detailDv">
                        <div className="header">
                          <h5 className="name">Joeby Ragpa </h5>
                          <p>- 12 April, 2014 at 16:50</p>
                        </div>
                        <p>
                          We possess within us two minds. So far I have written
                          only of the conscious mind. I would now like to
                          introduce you to your second mind,
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="reviewDv">
                  <div className="questionDv reviews">
                    <div className="user-img">
                      <figure>
                        <img src={User1} className="img-fluid" alt="" />
                      </figure>
                    </div>
                    <div className="detailDv">
                      <div className="header">
                        <h5 className="name">Joeby Ragpa </h5>
                        <p>- 12 April, 2014 at 16:50</p>
                      </div>
                      <p>
                        We possess within us two minds. So far I have written
                        only of the conscious mind. I would now like to
                        introduce you to your second mind, the hidden and
                        mysterious subconscious. Our subconscious mind contains
                        such power and complexity that it literally staggers the
                        imagination.
                      </p>
                      <div className="bottom-bar">
                        <div className="rating">
                          <ul className="stars">
                            <li>
                              <i className="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              <i className="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              <i className="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              <i className="fa fa-star-o" aria-hidden="true" />
                            </li>
                            <li>
                              <i className="fa fa-star-o" aria-hidden="true" />
                            </li>
                          </ul>
                          <p className="count">3.9</p>
                        </div>
                        <div className="rplyDv">
                          <a href="#">Reply</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="post-review">
                <div className="header">
                  <div className="left-side">
                    <figure>
                      <img src={User5} alt="" className="img-fluid" />
                    </figure>
                    <div className="name">
                      <h4>Bruce Wayne</h4>
                      <p className="tagline">
                        Your review will be Posted on Web
                      </p>
                    </div>
                  </div>
                  <div className="right-side">
                    <div className="rate">
                      <input
                        type="radio"
                        hidden
                        id="star5"
                        name="rate"
                        defaultValue={5}
                      />
                      <label htmlFor="star5" title="text">
                        5 stars
                      </label>
                      <input
                        type="radio"
                        hidden
                        id="star4"
                        name="rate"
                        defaultValue={4}
                      />
                      <label htmlFor="star4" title="text">
                        4 stars
                      </label>
                      <input
                        type="radio"
                        hidden
                        id="star3"
                        name="rate"
                        defaultValue={3}
                      />
                      <label htmlFor="star3" title="text">
                        3 stars
                      </label>
                      <input
                        type="radio"
                        hidden
                        id="star2"
                        name="rate"
                        defaultValue={2}
                      />
                      <label htmlFor="star2" title="text">
                        2 stars
                      </label>
                      <input
                        type="radio"
                        hidden
                        id="star1"
                        name="rate"
                        defaultValue={1}
                      />
                      <label htmlFor="star1" title="text">
                        1 star
                      </label>
                    </div>
                  </div>
                </div>
                <form action>
                  <textarea
                    placeholder="Write Your Review Here"
                    className="form-control"
                    defaultValue={""}
                  />
                  <button className="btn">
                    Submit review{" "}
                    <img src={ArrowImg} className="img-fluid" alt="" />
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="details">
                <div className="header">
                  <div className="heading">
                    <h4>Maples</h4>
                    <h3>Whisper Coneflower</h3>
                  </div>
                  <div className="price-tag">
                    <h5>
                      Unit Price : <span className="value">$50.50</span>
                    </h5>
                    {/* <h5>Bulk(10x) : <span class="value">$150.50</span></h5> */}
                  </div>
                </div>
                <div className="description">
                  <h4>Discription</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla
                  </p>
                </div>
                <div className="bulk_pricing">
                  <h4>Bulk Pricing </h4>
                  <p>
                    Below are the available bulk discount rates for each
                    individual item when you purchase a certain amount
                  </p>
                  <hr />
                  <ul>
                    <li>Buy 10 - 24 and get 10% off</li>
                    <li>Buy 25 or above and get 20% off</li>
                  </ul>
                </div>
                <div className="additional-info">
                  <h4>Additional Information </h4>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>
                          <span className="property">Latin Name:</span>{" "}
                          <span className="value">Lorem ipsum</span>
                        </li>
                        <li>
                          <span className="property">Common Name:</span>{" "}
                          <span className="value">Lorem ipsum</span>
                        </li>
                        <li>
                          <span className="property">Sun Exposure:</span>{" "}
                          <span className="value">Lorem ipsum</span>
                        </li>
                        <li>
                          <span className="property">Annual Growth:</span>{" "}
                          <span className="value">Lorem ipsum</span>
                        </li>
                        <li>
                          <span className="property">HxW @10 Years:</span>{" "}
                          <span className="value">Lorem ipsum</span>
                        </li>
                      </ul>
                      <div className="vertical-line" />
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>
                          <span className="property">Color:</span>{" "}
                          <span className="value">Lorem ipsum</span>
                        </li>
                        <li>
                          <span className="property">Leaf Type:</span>{" "}
                          <span className="value">Lorem ipsum</span>
                        </li>
                        <li>
                          <span className="property">Growth Rate:</span>{" "}
                          <span className="value">Lorem ipsum</span>
                        </li>
                        <li>
                          <span className="property">Hardiness Zone:</span>{" "}
                          <span className="value">Zone 3-8</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="variations">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="size">Select Size: </label>
                        <select id="size" className="form-control">
                          <option selected>Medium</option>
                          <option selected>Small</option>
                          <option selected>Large</option>
                          <option selected>XL</option>
                          <option selected>XXL</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="Add">Select Add On: </label>
                        <select id="Add" className="form-control">
                          <option selected>Lorem Ipsum</option>
                          <option selected>Lorem Ipsum</option>
                          <option selected>Lorem Ipsum</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="Quantity">Select Quantity </label>
                        <input
                          type="number"
                          id="Quantity"
                          defaultValue="01"
                          className="form-control"
                        />
                      </div>
                      {/* <div class="form-group">
                                    <label for="SelectAdds">Select Adds On: </label>
                                    <input type="number" id="SelectAdds" value="01" class="form-control">
                                </div> */}
                    </div>
                  </div>
                </div>
                <div className="donation">
                  <div className="title">
                    <h4>Donation</h4>
                  </div>
                  <div className="input-group">
                    <div className="form-group br-right">
                      <label htmlFor="Amount">Amount to donate: </label>
                      <select className="form-control" id="Amount">
                        <option>$5</option>
                        <option>$12</option>
                        <option>$20</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="Campaign">Select Campaign : </label>
                      <select className="form-control" id="Campaign">
                        <option>Save Nature</option>
                        <option>Save Nature1</option>
                        <option>Save Nature2</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="button-group">
                  <button
                    onclick="window.location.href='cart'"
                    className="btn"
                  >
                    Add to cart <img src={Cart1} className="img-fluid" alt="" />
                  </button>
                  <button
                    onclick="window.location.href='wishlist'"
                    className="btn"
                  >
                    Add to wishlist{" "}
                    <img src={Heart1} className="img-fluid" alt="" />
                  </button>
                </div>
                <div className="header-box header-box__growing-zone">
                  <div className="loading" style={{ display: "none" }} />
                  {/* <a  role="button">
                            <img class="header-box__icon" alt="location icon"
                                src="img/locationplace.svg"
                                loading="lazy">
                            <div class="header-box__content">
                                <span class="header-box__growing-zone-num">Growing Zone: <span
                                        class="nursery-growing-zone-num"></span></span>
                                <span class="header-box__city-state">London, St</span>
                            </div> 
                            <div class="header-box__arrow header-box__arrow--down"></div>
                        </a> */}
                  <div className="header-box__dropdown">
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          "\n                            .zone-box__content .error {\n                                display: none;\n                            }\n\n                            .zone-box__content .error_show {\n                                color: red;\n                                text-align: center;\n                            }\n\n                            .zone-box__content input.invalid,\n                            .zone-box__content textarea.invalid {\n                                border: 1px solid red;\n                            }\n\n                            .zone-box__content input.valid,\n                            .zone-box__content textarea.valid {\n                                border: 1px solid green;\n                            }\n                            ",
                      }}
                    />
                    <div className="site-header__box zone-box">
                      <div className="zone-box__content">
                        <form id="setGrowingZone">
                          {/* <label>Planting elsewhere?</label>
                                        <span class="error">* Not a valid Zip Code</span>
                                        <label class="visually-hidden" for="growingZone"
                                            aria-labelledby="growingZone">Growing Zone Zip Code</label> */}
                          <input
                            className="form-control"
                            type="text"
                            id="growingZone"
                            name="nursery_growing_zip"
                            defaultValue
                            placeholder="Enter Zip Code to Find Growing Zone"
                          />
                          <button
                            type="submit"
                            id="growingZoneBtn"
                            className="btn btn--default"
                          >
                            Find my Growing Zone
                          </button>
                          {/* <a class="gz-map-link"
                                            href="">What
                                            is a Growing Zone?</a> */}
                        </form>
                      </div>
                    </div>
                  </div>{" "}
                  {/* END dropdown-box--growing-zone */}
                </div>
                <div className="map">
                  <div className="map-img">
                    <figure>
                      <img src={MapImg} className="img-fluid" alt="" />
                    </figure>
                  </div>
                  <div className="map-zones">
                    <h4>Growing Zone : 4-8</h4>
                    {/* <h4>Hardness Zone</h4> */}
                    {/* <p>Click on the hardness zone number below to view on map</p>
                            <ul>
                                <li class="active"><a href="#">3</a></li>
                                <li><a href="#">4</a></li>
                                <li><a href="#">5</a></li>
                                <li><a href="#">6</a></li>
                                <li><a href="#">7</a></li>
                                <li><a href="#">8</a></li>
                            </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={ProAfter} className="img-fluid" alt="" />
          </figure>
        </div>
      </section>
      {/* Product Detail Sec End Here */}
      {/* Frequently Bought Sec Start Here */}
      <section className="frequently_bought">
        <div className="container">
          <div className="heading">
            <h2>Frequently Brought Together</h2>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="product-box pro_box" id="multi_select">
                <a href="#">
                  <div className="pro-img">
                    <figure>
                      <img src={Product2} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Whisper Coneflower</h4>
                    </div>
                    <div className="price">
                      <h4>$250.00</h4>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="product-box pro_box">
                <a href="#">
                  <div className="pro-img">
                    <figure>
                      <img src={Product3} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Whisper Coneflower</h4>
                    </div>
                    <div className="price">
                      <h4>$250.00</h4>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="product-box pro_box">
                <a href="#">
                  <div className="pro-img">
                    <figure>
                      <img src={Product4} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Whisper Coneflower</h4>
                    </div>
                    <div className="price">
                      <h4>$250.00</h4>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="product-box ">
                <a href="#">
                  <div className="pro-img">
                    <figure>
                      <img src={Product4} alt="" className="img-fluid" />
                    </figure>
                  </div>
                  <div className="pro-detail">
                    <div className="name">
                      <h4>Whisper Coneflower</h4>
                    </div>
                    <div className="price">
                      <h4>$250.00</h4>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="button-group frequentlybtn">
              <button onclick="window.location.href='cart'" className="btn">
                Add to cart <img src={Cart1} className="img-fluid" alt="" />
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* Frequently Bought Sec End Here */}

      <Footer />
    </>
  );
};

export default ProductDetail;
