import React from "react";
import "../assets/css/shop.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { BirdImg1, BirdImg2, BirdImg3, BirdImg4, SmallAtferImg, SmallBeforeImg } from "../constant";

const KigiBirds = () => {
  return (
    <>
      <Header />
      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>kigi birds</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* Shop Sec Start Here */}
      <section className="shop_sec">
        <div className="before">
          <figure>
            <img src={SmallAtferImg} className="img-fluid" alt="" />
          </figure>
        </div>
        <div className="container">
          <div className="heading">
            <h2>
              You can donate the amount used to take care of These Cute birds
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="product-box">
                <a href="bird-detail">
                  <div className="pro-img">
                    <figure>
                      <img src={BirdImg1} alt="" className="img-fluid" style={{width: 170}} />
                    </figure>
                  </div>
                </a>
                <div className="pro-detail">
                  <a href="bird-detail">
                    <div className="name">
                      <h4>Archimedes</h4>
                    </div>
                  </a>
                  <div className="price">
                    <a href="bird-detail"></a>
                    <a href="donate" className="btn">
                      Donate
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="product-box">
                <a href="bird-detail">
                  <div className="pro-img">
                    <figure>
                      <img src={BirdImg2} alt="" className="img-fluid" style={{width: 170}}/>
                    </figure>
                  </div>
                </a>
                <div className="pro-detail">
                  <a href="bird-detail">
                    <div className="name">
                      <h4>Archimedes</h4>
                    </div>
                  </a>
                  <div className="price">
                    <a href="bird-detail"></a>
                    <a href="donate" className="btn">
                      Donate
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="product-box">
                <a href="bird-detail">
                  <div className="pro-img">
                    <figure>
                      <img src={BirdImg3} alt="" className="img-fluid"  style={{width: 170}}/>
                    </figure>
                  </div>
                </a>
                <div className="pro-detail">
                  <a href="bird-detail">
                    <div className="name">
                      <h4>Archimedes</h4>
                    </div>
                  </a>
                  <div className="price">
                    <a href="bird-detail"></a>
                    <a href="donate" className="btn">
                      Donate
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="product-box">
                <a href="bird-detail">
                  <div className="pro-img">
                    <figure>
                      <img src={BirdImg4} alt="" className="img-fluid" style={{width: 170}} />
                    </figure>
                  </div>
                </a>
                <div className="pro-detail">
                  <a href="bird-detail">
                    <div className="name">
                      <h4>Archimedes</h4>
                    </div>
                  </a>
                  <div className="price">
                    <a href="bird-detail"></a>
                    <a href="donate" className="btn">
                      Donate
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="product-box">
                <a href="bird-detail">
                  <div className="pro-img">
                    <figure>
                      <img src={BirdImg1} alt="" className="img-fluid" style={{width: 170}} />
                    </figure>
                  </div>
                </a>
                <div className="pro-detail">
                  <a href="bird-detail">
                    <div className="name">
                      <h4>Archimedes</h4>
                    </div>
                  </a>
                  <div className="price">
                    <a href="bird-detail"></a>
                    <a href="donate" className="btn">
                      Donate
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="product-box">
                <a href="bird-detail">
                  <div className="pro-img">
                    <figure>
                      <img src={BirdImg2} alt="" className="img-fluid" style={{width: 170}} />
                    </figure>
                  </div>
                </a>
                <div className="pro-detail">
                  <a href="bird-detail">
                    <div className="name">
                      <h4>Archimedes</h4>
                    </div>
                  </a>
                  <div className="price">
                    <a href="bird-detail"></a>
                    <a href="donate" className="btn">
                      Donate
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="product-box">
                <a href="bird-detail">
                  <div className="pro-img">
                    <figure>
                      <img src={BirdImg3} alt="" className="img-fluid"  style={{width: 170}}/>
                    </figure>
                  </div>
                </a>
                <div className="pro-detail">
                  <a href="bird-detail">
                    <div className="name">
                      <h4>Archimedes</h4>
                    </div>
                  </a>
                  <div className="price">
                    <a href="bird-detail"></a>
                    <a href="donate" className="btn">
                      Donate
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="product-box">
                <a href="bird-detail">
                  <div className="pro-img">
                    <figure>
                      <img src={BirdImg4} alt="" className="img-fluid" style={{width: 170}} />
                    </figure>
                  </div>
                </a>
                <div className="pro-detail">
                  <a href="bird-detail">
                    <div className="name">
                      <h4>Archimedes</h4>
                    </div>
                  </a>
                  <div className="price">
                    <a href="bird-detail"></a>
                    <a href="donate" className="btn">
                      Donate
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="product-box">
                <a href="bird-detail">
                  <div className="pro-img">
                    <figure>
                      <img src={BirdImg1} alt="" className="img-fluid" style={{width: 170}} />
                    </figure>
                  </div>
                </a>
                <div className="pro-detail">
                  <a href="bird-detail">
                    <div className="name">
                      <h4>Archimedes</h4>
                    </div>
                  </a>
                  <div className="price">
                    <a href="bird-detail"></a>
                    <a href="donate" className="btn">
                      Donate
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="product-box">
                <a href="bird-detail">
                  <div className="pro-img">
                    <figure>
                      <img src={BirdImg2} alt="" className="img-fluid"  style={{width: 170}}/>
                    </figure>
                  </div>
                </a>
                <div className="pro-detail">
                  <a href="bird-detail">
                    <div className="name">
                      <h4>Archimedes</h4>
                    </div>
                  </a>
                  <div className="price">
                    <a href="bird-detail"></a>
                    <a href="donate" className="btn">
                      Donate
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="product-box">
                <a href="bird-detail">
                  <div className="pro-img">
                    <figure>
                      <img src={BirdImg3} alt="" className="img-fluid"  style={{width: 170}}/>
                    </figure>
                  </div>
                </a>
                <div className="pro-detail">
                  <a href="bird-detail">
                    <div className="name">
                      <h4>Archimedes</h4>
                    </div>
                  </a>
                  <div className="price">
                    <a href="bird-detail"></a>
                    <a href="donate" className="btn">
                      Donate
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="product-box">
                <a href="bird-detail">
                  <div className="pro-img">
                    <figure>
                      <img src={BirdImg4} alt="" className="img-fluid" style={{width: 170}} />
                    </figure>
                  </div>
                </a>
                <div className="pro-detail">
                  <a href="bird-detail">
                    <div className="name">
                      <h4>Archimedes</h4>
                    </div>
                  </a>
                  <div className="price">
                    <a href="bird-detail"></a>
                    <a href="donate" className="btn">
                      Donate
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="paginatio-count">
            <ul className="pagination-list">
              <li className="pagi arrow">
                <a href="#">
                  <i className="fa fa-angle-left" aria-hidden="true" />
                </a>
              </li>
              <li className="pagi">
                <a href="#">1</a>
              </li>
              <li className="pagi active">
                <a href="#">2</a>
              </li>
              <li className="pagi">
                <a href="#">...</a>
              </li>
              <li className="pagi">
                <a href="#">12</a>
              </li>
              <li className="pagi arrow">
                <a href="#">
                  <i className="fa fa-angle-right" aria-hidden="true" />
                </a>
              </li>
            </ul>
            <p className="total-pages">12 PRODUCTS 1 - 12</p>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={SmallBeforeImg} className="img-fluid" alt="" />
          </figure>
        </div>
      </section>
      {/* Shop Sec End Here */}

      <Footer />
    </>
  );
};

export default KigiBirds;
