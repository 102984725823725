import React from "react";
import "../assets/css/donate.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { CardImage1, CardImage2 } from "../constant";
import Chart from "./Chart";

export const data = [
  ["Pac Man", "Percentage"],
  ["", 75],
  ["", 25],
];

export const options = {
  legend: "none",
  pieSliceText: "none",
  pieStartAngle: 135,
  tooltip: { trigger: "none" },
  slices: {
    0: { color: "yellow" },
    1: { color: "transparent" },
  },
};

const Donation = () => {
  return (
    <>
      <Header />
      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>Donate</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* section  donation start here*/}
      <section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="dntn_prod">
                  <div>
                    <a href="campaign">
                      <img src={CardImage1} className="img-fluid" alt="" />
                    </a>
                  </div>
                  <div className="text-center">
                    <h4>Service Center</h4>
                  </div>
                  <div className="dntn_prod_desc">
                    <div>
                      {/* custom-loader Include Here */}
                      <div className="graph-donut">
                        <Chart data={"canvas"} />
                        <span className="percent" />
                        <span className="bott_txt">Funded</span>
                      </div>
                      {/* custom-loader Include Here */}
                    </div>
                    <div className="pledged_dv">
                      <p>%</p>
                      <span className="bott_txt">Funded</span>
                    </div>
                    <div className="pledged_dv">
                      <p>$56,116</p>
                      <span className="bott_txt">Pledged</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dntn_prod">
                  <div>
                    <a href="campaign">
                      <img src={CardImage2} className="img-fluid" alt="" />
                    </a>
                  </div>
                  <div className="text-center">
                    <h4>Service Center</h4>
                  </div>
                  <div className="dntn_prod_desc">
                    <div>
                      {/* custom-loader Include Here */}
                      <div className="graph-container">
                        <div className="graph-donut">
                          <Chart data={"canvas1"} />
                          <span className="percent" />
                          <span className="bott_txt">Funded</span>
                        </div>
                      </div>{" "}
                      {/* custom-loader Include Here */}
                    </div>
                    <div></div>
                    <div className="pledged_dv">
                      <p>$56,116</p>
                      <span className="bott_txt">Pledged</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dntn_prod">
                  <div>
                    <a href="campaign">
                      <img src={CardImage1} className="img-fluid" alt="" />
                    </a>
                  </div>
                  <div className="text-center">
                    <h4>Service Center</h4>
                  </div>
                  <div className="dntn_prod_desc">
                    <div>
                      {/* custom-loader Include Here */}
                      <div className="graph-container">
                        <div className="graph-donut">
                          <Chart data={"canvas3"} />
                          <span className="percent" />
                          <span className="bott_txt">Funded</span>
                        </div>
                      </div>{" "}
                      {/* custom-loader Include Here */}
                    </div>
                    <div></div>
                    <div className="pledged_dv">
                      <p>$56,116</p>
                      <span className="bott_txt">Pledged</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dntn_prod">
                  <div>
                    <a href="campaign">
                      <img src={CardImage2} className="img-fluid" alt="" />
                    </a>
                  </div>
                  <div className="text-center">
                    <h4>Service Center</h4>
                  </div>
                  <div className="dntn_prod_desc">
                    <div>
                      {/* custom-loader Include Here */}
                      <div className="graph-container">
                        <div className="graph-donut">
                          <Chart data={"canvas4"} />
                          <span className="percent" />
                          <span className="bott_txt">Funded</span>
                        </div>
                      </div>{" "}
                      {/* custom-loader Include Here */}
                    </div>
                    <div></div>
                    <div className="pledged_dv">
                      <p>$56,116</p>
                      <span className="bott_txt">Pledged</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dntn_prod">
                  <div>
                    <a href="campaign">
                      <img src={CardImage1} className="img-fluid" alt="" />
                    </a>
                  </div>
                  <div className="text-center">
                    <h4>Service Center</h4>
                  </div>
                  <div className="dntn_prod_desc">
                    <div>
                      {/* custom-loader Include Here */}
                      <div className="graph-container">
                        <div className="graph-donut">
                          <Chart data={"canvas5"} />
                          <span className="percent" />
                          <span className="bott_txt">Funded</span>
                        </div>
                      </div>{" "}
                      {/* custom-loader Include Here */}
                    </div>
                    <div></div>
                    <div className="pledged_dv">
                      <p>$56,116</p>
                      <span className="bott_txt">Pledged</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dntn_prod">
                  <div>
                    <a href="campaign">
                      <img src={CardImage2} className="img-fluid" alt="" />
                    </a>
                  </div>
                  <div className="text-center">
                    <h4>Service Center</h4>
                  </div>
                  <div className="dntn_prod_desc">
                    <div>
                      {/* custom-loader Include Here */}
                      <div className="graph-container">
                        <div className="graph-donut">
                          <Chart data={"canvas6"} />
                          <span className="percent" />
                          <span className="bott_txt">Funded</span>
                        </div>
                      </div>{" "}
                      {/* custom-loader Include Here */}
                    </div>
                    <div></div>
                    <div className="pledged_dv">
                      <p>$56,116</p>
                      <span className="bott_txt">Pledged</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
};

export default Donation;
