import React from "react";
import "../assets/css/about.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { about1, about2, ArrowImg, ArrowImg1, Btn, SmallAtferImg, SmallBeforeImg, work1, work2, work3 } from "../constant";

const About = () => {
  return (
    <>
      <Header />

      {/* InnerBanner Sec Start Here */}
      <section className="innerBanner">
        <div className="container">
          <div className="inner-box">
            <div className="title">
              <h2>About</h2>
            </div>
          </div>
        </div>
      </section>
      {/* InnerBanner Sec End Here */}
      {/* About Sec Start Here */}
      <section className="about_sec">
        <div className="before">
          <figure>
            <img src={SmallAtferImg} className="img-fluid" />
          </figure>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="content-wrapper">
                <h2>deserunt mollit anim id est laborum.</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident.
                  <br />
                  <br />
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident.
                  <br />
                  <br />
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                {/* <div class="button-group">
                        <a href="#" class="btn"><span>Submit review</span> <img src="img/arrow.png"
                                class="img-fluid"></a>
                    </div> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img_box">
                <figure>
                  <img src={about1} className="img-fluid" />
                </figure>
              </div>
            </div>
          </div>
          <div className="row align-items-center scnd_row mt-5 pt-3">
            <div className="col-lg-6">
              <div className="img_box">
                <figure>
                  <img src={about2} className="img-fluid" />
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-wrapper">
                <h2>deserunt mollit anim id est laborum.</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident.
                  <br />
                  <br />
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident.
                  <br />
                  <br />
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                {/* <div class="button-group">
                        <a href="#" class="btn"><span>Submit review</span> <img src="img/arrow.png"
                                class="img-fluid"></a>
                    </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="after">
          <figure>
            <img src={SmallBeforeImg} className="img-fluid" />
          </figure>
        </div>
      </section>
      {/* About Sec End Here */}
      {/* How It Work Sec Start Here */}
      <section className="how_it_work">
        <div className="container">
          <div className="discover">
            <div className="heading_wrapper">
              <h2>How It Works</h2>
            </div>
            <div className="view-all">
              <a href="/" className="lnk">
                Read More <img src={Btn} className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="wor_box">
                <div className="img_box">
                  <figure>
                    <img src={work1} className="img-fluid" />
                  </figure>
                </div>
                <div className="content">
                  <h4>Step 1</h4>
                  <h5>
                    <span>We grow them in</span>
                    <br /> Optimal Environment{" "}
                  </h5>
                </div>
                <div className="arrow">
                  <figure>
                    <img src={ArrowImg1} className="img-fluid" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="wor_box">
                <div className="img_box">
                  <figure>
                    <img src={work2} className="img-fluid" />
                  </figure>
                </div>
                <div className="content">
                  <h4>Step 2</h4>
                  <h5>
                    <span>We Box them at the</span>
                    <br /> Right time
                  </h5>
                </div>
                <div className="arrow">
                  <figure>
                    <img src={ArrowImg1} className="img-fluid" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="wor_box">
                <div className="img_box">
                  <figure>
                    <img src={work3} className="img-fluid" />
                  </figure>
                </div>
                <div className="content">
                  <h4>Step 3</h4>
                  <h5>
                    <span>We deliver to</span>
                    <br /> Your Doorstep
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* How It Work Sec End Here */}
      <Footer />
    </>
  );
};

export default About;
