import React from "react";
import "../assets/css/checkout.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { CartProImg, ProAfter, ProBefore, pymnt1, pymnt2, pymnt3, pymnt4, pymnt5 } from "../constant";

const Checkout = () => {
  return (
    <>
      <Header />
 {/* InnerBanner Sec Start Here */}
 <section className="innerBanner">
          <div className="container">
            <div className="inner-box">
              <div className="title">
                <h2>Checkout</h2>
              </div>
            </div>
          </div>
        </section>
        {/* InnerBanner Sec End Here */}
        {/* Checkout Start Here */}
        <section className="checkout">
          <div className="before">
            <figure><img src={ProBefore} className="img-fluid" alt="" /></figure>
          </div>
          <div className="container">
            <form action="thank-you">
              <div className="row">
                <div className="col-lg-6">
                  <div className="first-top-bar">
                    <h3>Shipment Details</h3>
                    <p>Already Have an account?<a href="login">Log in</a></p>
                  </div>
                  <div className="saved-address">
                    <div className="title">
                      <h5>Use Saved Addresses</h5>
                    </div>
                    {/* Saved Address */}
                    <label className="container">
                      <input type="radio" defaultChecked="checked" name="radio" />
                      <span className="checkmark">
                        <div className="select-address">
                          <div className="selection">
                            <i className="fa fa-check" aria-hidden="true" />
                          </div>
                          <div className="radio-btn">
                            <span />
                          </div>
                          <div className="address-detail">
                            <div className="top">
                              <h5><span className="property">Country:</span><span className="value">United
                                  States</span>
                              </h5>
                              <h5><span className="property pl-4">State:</span><span className="value">Texas</span>
                              </h5>
                            </div>
                            <h5><span className="property">Address:</span><span className="value">10515 Fox Ave
                                Fairdale, Kentucky(KY), 40118</span></h5>
                            <h5 className="last"><span className="property">Phone:</span><span className="value">+44-123-456-789</span>
                            </h5>
                            <div className="button-group">
                              <button className="btn default disabled">Default</button>
                            </div>
                          </div>
                        </div>
                      </span>
                    </label>
                    {/* Saved Address */}
                  </div>
                  <div className="add-new-address">
                    <label className="container"> Add New Address
                      <input type="radio" name="radio" />
                      <span className="checkmark" />
                      <div className="custom_form">
                        <div className="row">
                          {/* <div class="col-md-12">
                                <div class="form-group">
                                    <label for="method">Shipment Method and delivery fees</label>
                                    <select id="method" class="form-control">
                                        <option selected>Select Shipment method</option>
                                        <option>Select Shipment method 1</option>
                                        <option>Select Shipment method 2</option>
                                        <option>Select Shipment method 3</option>
                                    </select>
                                </div>
                            </div> */}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="Address">First Name</label>
                              <input type="text" id="fname" className="form-control" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="Address">Last Name</label>
                              <input type="text" id="lname" className="form-control" />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="Address">Address Line1</label>
                              <input type="text" id="Address" className="form-control" />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="Address">Address Line2</label>
                              <input type="text" id="Address" className="form-control" />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="Suite">Suite, Apartment, etc </label>
                              <input type="text" id="Suite" className="form-control" />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="City">City</label>
                              <input type="text" id="City" className="form-control" />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="Country">Country/Region</label>
                              <input type="text" id="Country" className="form-control" />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="State">State</label>
                              <input type="text" id="State" className="form-control" />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="Zip">Zip Code</label>
                              <input type="text" id="Zip" className="form-control" />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="checkbox">
                              <input type="checkbox" className="form-check-input" id="check" />
                              <label htmlFor="check">Save Shipping Details</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="payment-details">
                    <div className="heading">
                      <h3>Payment Details</h3>
                    </div>
                    <div className="saved-payment-method">
                      {/* <h5>Use Saved Payments</h5>
                            <label class="container">
                                <input type="radio" checked="checked" name="payment-radio">
                                <span class="checkmark">
                                    <div class="card-details">
                                        <div class="radio-btn">
                                            <span></span>
                                        </div>
                                        <div class="visa-card">
                                            <div class="img-box">
                                                <figure><img src="img/visa.png" alt="No Image Found">
                                                </figure>
                                            </div>
                                            <div class="input-box">
                                                <label for="card-number">My Personal Card</label><br />
                                                <input id="card-number" value="**********1239" type="text" readonly>
                                            </div>
                                        </div>
                                        <div class="extra-btn">
                                            <button class="btn default disabled">Default</button>
                                            <div class="selection">
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </label>

                            <label class="container second-card">
                                <input type="radio" name="payment-radio">
                                <span class="checkmark">
                                    <div class="card-details">
                                        <div class="radio-btn">
                                            <span></span>
                                        </div>
                                        <div class="visa-card">
                                            <div class="img-box">
                                                <figure><img src="img/visa.png" alt="No Image Found">
                                                </figure>
                                            </div>
                                            <div class="input-box">
                                                <label for="card-number">My Personal Card</label><br />
                                                <input id="card-number" value="**********1239" type="text" readonly>
                                            </div>
                                        </div>
                                        <div class="extra-btn">
                                            <button class="btn default disabled">Default</button>
                                            <div class="selection">
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </label> */}
                      <div className="add-new-method">
                        {/* <label class="container"> Add New Payment
                                    <input type="radio" checked="checked" name="payment-radio">
                                    <span class="checkmark"></span>
                                </label> */}
                        <div className="form-group">
                          <input type="text" placeholder="Write Your Card Number " className="form-control" />
                        </div>
                      </div>
                      <div className="card-type">
                        <label className="container">
                          <input type="radio" defaultChecked="checked" name="radio" />
                          <span className="checkmark">
                            <figure><img src={pymnt1} className="img-fluid"alt="" /></figure>
                          </span>
                        </label>
                        <label className="container">
                          <input type="radio" name="radio" />
                          <span className="checkmark">
                            <figure><img src={pymnt2} className="img-fluid" alt=""/></figure>
                          </span>
                        </label>
                        <label className="container">
                          <input type="radio" name="radio" />
                          <span className="checkmark">
                            <figure><img src={pymnt3} className="img-fluid" alt=""/></figure>
                          </span>
                        </label>
                        <label className="container">
                          <input type="radio" name="radio" />
                          <span className="checkmark">
                            <figure><img src={pymnt4} className="img-fluid" alt="" /></figure>
                          </span>
                        </label>
                        <label className="container">
                          <input type="radio" name="radio" />
                          <span className="checkmark">
                            <figure><img src={pymnt5} className="img-fluid" alt="" /></figure>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="second-top-bar">
                    <div className="title pb-4">
                      <h3>Shipment Details</h3>
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Enter Promo Code" />
                      <button type="button">APPLY</button>
                    </div>
                  </div>
                  <div className="cart-product-detail">
                    <div className="title">
                      <h3>Products Details</h3>
                    </div>
                    <div className="checkout-products">
                      <div className="products">
                        <div className="abt-product">
                          <div className="img-box">
                            <figure><img src={CartProImg} alt={123} /></figure>
                          </div>
                          <div className="pro-dtl">
                            <h5 className="name">Whisper Coneflower</h5>
                            <ul className="variations">
                              <li><span className="property">Color:</span> <span className="value"> Green</span>
                              </li>
                              <li><span className="property">Leaf Type:</span> <span className="value">Lorem</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="product-price">
                          <h4 className="price">$158.07 </h4>
                        </div>
                      </div>
                      <div className="products">
                        <div className="abt-product">
                          <div className="img-box">
                            <figure><img src={CartProImg} alt={123} /></figure>
                          </div>
                          <div className="pro-dtl">
                            <h5 className="name">Whisper Coneflower</h5>
                            <ul className="variations">
                              <li><span className="property">Color:</span> <span className="value"> Green</span>
                              </li>
                              <li><span className="property">Leaf Type:</span> <span className="value">Lorem</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="product-price">
                          <h4 className="price">$158.07 </h4>
                        </div>
                      </div>
                      <div className="products">
                        <div className="abt-product">
                          <div className="img-box">
                            <figure><img src={CartProImg} alt={123} /></figure>
                          </div>
                          <div className="pro-dtl">
                            <h5 className="name">Whisper Coneflower</h5>
                            <ul className="variations">
                              <li><span className="property">Color:</span> <span className="value"> Green</span>
                              </li>
                              <li><span className="property">Leaf Type:</span> <span className="value">Lorem</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="product-price">
                          <h4 className="price">$158.07 </h4>
                        </div>
                      </div>
                      <div className="products">
                        <div className="abt-product">
                          <div className="img-box">
                            <figure><img src="img/cart-pro.png" alt={123} /></figure>
                          </div>
                          <div className="pro-dtl">
                            <h5 className="name">Whisper Coneflower</h5>
                            <ul className="variations">
                              <li><span className="property">Color:</span> <span className="value"> Green</span>
                              </li>
                              <li><span className="property">Leaf Type:</span> <span className="value">Lorem</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="product-price">
                          <h4 className="price">$158.07 </h4>
                        </div>
                      </div>
                      <div className="products">
                        <div className="abt-product">
                          <div className="img-box">
                            <figure><img src="img/cart-pro.png" alt={123} /></figure>
                          </div>
                          <div className="pro-dtl">
                            <h5 className="name">Whisper Coneflower</h5>
                            <ul className="variations">
                              <li><span className="property">Color:</span> <span className="value"> Green</span>
                              </li>
                              <li><span className="property">Leaf Type:</span> <span className="value">Lorem</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="product-price">
                          <h4 className="price">$158.07 </h4>
                        </div>
                      </div>
                      <div className="products">
                        <div className="abt-product">
                          <div className="img-box">
                            <figure><img src="img/cart-pro.png" alt={123} /></figure>
                          </div>
                          <div className="pro-dtl">
                            <h5 className="name">Whisper Coneflower</h5>
                            <ul className="variations">
                              <li><span className="property">Color:</span> <span className="value"> Green</span>
                              </li>
                              <li><span className="property">Leaf Type:</span> <span className="value">Lorem</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="product-price">
                          <h4 className="price">$158.07 </h4>
                        </div>
                      </div>
                    </div>
                    <div className="pricing-details">
                      <div className="item-total">
                        <h4 className="property">Item Total :</h4>
                        <h4 className="value">$50</h4>
                      </div>
                      <div className="item-total">
                        <h4 className="property">Shipment &amp; Delivery</h4>
                        <h4 className="value">$0</h4>
                      </div>
                      <div className="item-total">
                        <h4 className="property">Promo Applied :</h4>
                        <h4 className="value">-$0</h4>
                      </div>
                    </div>
                    <div className="order-total">
                      <h3 className="property">Order Total</h3>
                      <h3 className="value">$45</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-last" />
              <div className="confirm-orderDv">
                <div className="checkbox">
                  <input type="checkbox" className="form-check-input" id="for-checkbox" />
                  <label htmlFor="for-checkbox">Proident, sunt in culpa qui officia deserunt mollit anim id est
                    laborum.</label>
                </div>
                <div className="button-group">
                  <button type="submit">Confirm Order</button>
                </div>
              </div>
            </form>
          </div>
          <div className="after">
            <figure><img src={ProAfter} className="img-fluid" alt=""/></figure>
          </div>
        </section>
        {/* Checkout End Here */}
   
      <Footer />
    </>
  );
};

export default Checkout;
